import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    Col,
    Container,
    Row,
    Spinner,
} from "reactstrap";
import nexteatsImg from "../../src/assets/img/nexteatsImg.png";
import { getAllRestaurantList } from "../store/actions/restaurantsAction";

const RestaurantCard = ({ searchValue }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [restaurants, setRestaurants] = useState([]);
    const { allRestaurants, requestLoading } = useSelector(
        (state) => state.restaurants
    );
    useEffect(() => {
        dispatch(getAllRestaurantList());
    }, []);
    useEffect(() => {
        if (allRestaurants?.length > 0) {
            setRestaurants(allRestaurants);
        }
    }, [allRestaurants]);
    useEffect(() => {
        if (searchValue) {
            const filteredRestaurants = allRestaurants?.filter((restaurant) =>
                restaurant?.name
                    ?.toLowerCase()
                    .includes(searchValue.toLowerCase())
            );
            setRestaurants(filteredRestaurants);
        } else {
            setRestaurants(allRestaurants);
        }
    }, [allRestaurants, searchValue]);
    const areAllValuesEmpty = (obj) => {
        return obj?.every(item => !item.endTime && !item.startTime);
      }
    return (
        <Container className="product_details">
            {requestLoading ? (
                <Row>
                    <Spinner size="sm" color="primary" />
                </Row>
            ) : (
                <>
                    {restaurants?.length > 0 ? (
                        <Row>
                            {restaurants?.map((restaurant) => {
                                const hasNameAndLocation =
                                    restaurant?.name && restaurant?.location;

                                return (
                                    hasNameAndLocation && (
                                        <Col
                                            lg="4"
                                            md="4"
                                            sm="6"
                                            xs="10"
                                            className="mb-3"
                                            key={restaurant?.id}
                                        >
                                            <Card
                                                className="my-12 cursor-pointer"
                                                onClick={() => {
                                                    history.push(
                                                        `/restaurant/${restaurant?.id}`
                                                    );
                                                }}
                                            >
                                                <div className="position-relative d-flex align-items-center">
                                                    <img
                                                        className="card-img-top"
                                                        src={
                                                            restaurant
                                                                ?.restaurantImages?.[0] ||
                                                            nexteatsImg
                                                        }
                                                        alt="Card cap"
                                                        style={{
                                                            height: "250px",
                                                            width: "100%",
                                                        }}
                                                    />
                                                </div>
                                                <CardBody>
                                                    <CardTitle
                                                        tag="h5"
                                                        className="pb-4 mb-0 text-capitalize"
                                                    >
                                                        {restaurant?.name}
                                                    </CardTitle>
                                                    <CardSubtitle
                                                        tag="h6"
                                                        className="mb-2 text-muted text-capitalize"
                                                    >
                                                        <span className="me-1">
                                                            {
                                                                restaurant?.location
                                                            }
                                                        </span>
                                                    </CardSubtitle>
                                                    <hr className="mx-4 mb-1" />
                                                    {areAllValuesEmpty(restaurant?.storeTiming)===false ?
                                                    <>
                                                    <CardTitle
                                                        tag="h5"
                                                        className="py-4 mb-0"
                                                    >
                                                        Availability
                                                    </CardTitle>
                                                    <div className="d-flex">
                                                        <Row className="px-3">
                                                            {restaurant?.storeTiming?.map(
                                                                (
                                                                    availableDay,
                                                                    idx
                                                                ) => {
                                                                    if (
                                                                        availableDay.startTime &&
                                                                        availableDay.endTime
                                                                    ) {
                                                                        return (
                                                                            <Badge
                                                                                key={
                                                                                    idx
                                                                                }
                                                                                color="info"
                                                                                className="px-3 mr-2 py-2 cursor-pointer mb-2"
                                                                            >
                                                                                {
                                                                                    availableDay?.day
                                                                                }
                                                                            </Badge>
                                                                        );
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                }
                                                            )}
                                                        </Row>
                                                    </div>
                                                    </>:<></>
                            }
                                                    <Button
                                                        color="primary"
                                                        className="mt-3 mx-auto border-0"
                                                        style={{
                                                            background: `#d70365`,
                                                        }}
                                                        onClick={() => {
                                                            history.push(
                                                                `/restaurant/${restaurant?.id}`
                                                            );
                                                        }}
                                                    >
                                                        Visit Restaurant
                                                    </Button>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )
                                );
                            })}
                        </Row>
                    ) : (
                        <p className="text-center">No Restaurants to show</p>
                    )}
                </>
            )}
        </Container>
    );
};

export default RestaurantCard;
