import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Modal,
    ModalBody,
    Row,
    Col,
    FormGroup,
    Input,
    Spinner,
} from "reactstrap";
import ReactStars from "react-rating-stars-component";
import closeLight from "../assets/img/closeLight.svg";
import orderComplete from "../assets/img/orderComplete.svg";
import star from "../assets/img/star.svg";
import { addRestaurantFeedBack } from "../store/actions/restaurantsAction";
import { useHistory } from "react-router-dom";

const OrderCompleted = ({ toggle, modal }) => {
    const { darkMode, color } = useSelector((state) => state.global);
    const { restaurantsDetail } = useSelector((state) => state.restaurants);

    const stars = [star, star, star, star, star];
    const id = "JAlzC8claBA5zE2c1Lub";
    const { orderTracker, requestLoading, feedLoader } = useSelector(
        (state) => state.restaurants
    );
    const dispatch = useDispatch();
    const [rating, setRating] = useState();
    const [comment, setComment] = useState("");
    const ratingChanged = (newRating) => {
        setRating(newRating);
    };
    const history = useHistory();
    const addFeedback = () => {
        let obj = {
            rating,
            comment,
            restaurantID: orderTracker?.restaurantID,
            customerID: orderTracker?.customerID,
            orderID: orderTracker?.id,
        };

        dispatch(
            addRestaurantFeedBack(obj, () => {
                toggle();
                setComment("");
                setRating();
                history.push(`/restaurant/${restaurantsDetail?.restaurantID}`);
            })
        );
    };

    return (
        <Modal
            isOpen={modal}
            toggle={toggle}
            className={`${
                darkMode ? "order_complete_modal-dark" : "order_complete_modal"
            }`}
        >
            <ModalBody>
                <Row className="mx-2 mb-3 justify-content-end align-items-center">
                    <img
                        src={closeLight}
                        alt="Light"
                        onClick={toggle}
                        className="cursor-pointer"
                    />
                </Row>
                <Col className="d-flex flex-column">
                    <Col className="d-flex flex-column justify-content-center align-items-center">
                        <img src={orderComplete} alt="Order" />
                        <h5 className="fs--16 fw--700 mt-2 title">
                            Order Completed
                        </h5>
                    </Col>
                    <Row className="justify-content-center my-3">
                        <ReactStars
                            count={5}
                            name="rating"
                            onChange={ratingChanged}
                            size={30}
                            isHalf={true}
                            emptyIcon={<i className="far fa-star"></i>}
                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                            activeColor={color}
                        />
                        {}
                    </Row>
                    <FormGroup>
                        <Input
                            id="exampleText"
                            name="text"
                            type="textarea"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            rows={4}
                            placeholder="placeholder"
                            className="text-area"
                        />
                    </FormGroup>
                    <Button
                        style={{ background: `${color}` }}
                        className="top_cards__addtocart fs--16 fw--400 my-3"
                        onClick={addFeedback}
                        disabled={feedLoader}
                    >
                        {feedLoader ? <Spinner size="sm" /> : "Submit"}
                    </Button>
                </Col>
            </ModalBody>
        </Modal>
    );
};

export default OrderCompleted;
