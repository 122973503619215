import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function Paypal(props) {
    const { amount, forwardFees, getPaypalDetail, setPayment } = props;
    const { restaurantsDetail } = useSelector((state) => state.restaurants);
    const paypal = useRef();

    function calculateFinalAmount(amount, forwardFees) {
        if (forwardFees?.type == "fixed_price") {
            return amount + parseFloat(forwardFees?.fixedAmount);
        } else if (forwardFees?.type == "percentage") {
            const percentage = parseFloat(forwardFees?.percentageAmount) / 100;
            return amount + amount * percentage;
        } else if (forwardFees?.type == "both") {
            let percentage = parseFloat(forwardFees?.percentageAmount) / 100;
            return (
                amount +
                parseFloat(forwardFees?.fixedAmount) +
                amount * percentage
            );
        }
    }
    const finalAmount = useMemo(
        () => calculateFinalAmount(amount, forwardFees),
        [amount, forwardFees]
    );

    let taxAmount = "";

    if (forwardFees?.type === "fixed_price") {
        taxAmount = forwardFees.fixedAmount;
    } else if (forwardFees?.type === "percentage") {
        taxAmount = parseFloat(forwardFees.percentageAmount);
    } else if (forwardFees?.type === "both") {
        taxAmount = `${parseFloat(forwardFees.percentageAmount)}% + ${
            forwardFees.fixedAmount
        }`;
    }

    useEffect(() => {
        const script = document.createElement("script");
        script.src = `https://www.paypal.com/sdk/js?client-id=${restaurantsDetail?.paypalClientKey}&disable-funding=credit,card&vault=true`;

        document.body.appendChild(script);

        script.addEventListener("load", () => {
            if (window.paypal && window.paypal.Buttons) {
                window.paypal
                    .Buttons({
                        createOrder: (data, actions, err) => {
                            return actions.order.create({
                                intent: "CAPTURE",
                                purchase_units: [
                                    {
                                        reference_id: "default",
                                        description: "Account Fee",
                                        amount: {
                                            currency_code: "USD",
                                            value: finalAmount.toString(),
                                        },
                                    },
                                ],
                            });
                        },
                        onApprove: async (data, actions) => {
                            const order = await actions.order.capture();
                            props.getPaypalDetail(order, finalAmount);
                            props.setPayment("Paypal");
                        },
                        onError: (err) => {
                            toast.error(err.message);
                        },
                    })
                    .render(paypal.current);
            } else {
            }
        });

        return () => {
            document.body.removeChild(script);
        };
    }, [finalAmount]);

    return (
        <>
            <div ref={paypal}></div>
            <div className="d-flex justify-content-center my-2">
                <span className="fs--22 fw--400 mr-2">
                    Tax Fees: {}
                    {taxAmount}
                </span>
                <span className="fs--22 fw--400">
                    {" "}
                    {props?.forwardFees?.type == "percentage"
                        ? "%"
                        : props.orders?.order[0]?.currency}
                </span>
            </div>
            <div className="d-flex justify-content-center my-1">
                <span className="fs--22 fw--400 mr-1">
                    Total Amount: {finalAmount ? finalAmount?.toFixed(2) : 0}
                </span>
                <span className="fs--22 fw--400">
                    {props.orders?.order[0]?.currency}
                </span>
            </div>
        </>
    );
}

export default Paypal;
