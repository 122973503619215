const initialState = {
    parentMenuData: [],
    menuCategories: [],
    menuLoading: false,
    categoriesLoading: false,
    menuItems: [],
};

export default function MenuReducers(state = initialState, { type, payload }) {
    switch (type) {
        case "PARENT_MENUS":
            return {
                ...state,
                parentMenuData: payload,
            };
        case "MENU_CATEGORIES":
            return {
                ...state,
                menuCategories: payload,
            };
        case "CATEGORIES_LOADER":
            return {
                ...state,
                categoriesLoading: payload,
            };
        case "MENU_ITEMS":
            return {
                ...state,
                menuItems: payload,
            };
        case "MENU_LOADER":
            return {
                ...state,
                menuLoading: payload,
            };

        default:
            return { ...state };
    }
}
