import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import MainNavigation from "../components/MainNavigation";
import CardItem from "../components/CardItem";
import { useDispatch, useSelector } from "react-redux";
import {
    getLikedItems,
    getRestaurantsAction,
} from "../store/actions/restaurantsAction";

const LikedItems = () => {
    const dispatch = useDispatch();

    let { uid, user } = useSelector((state) => state.authUser);
    const { LikedMenu, restaurantsDetail } = useSelector(
        (state) => state.restaurants
    );
    const currency = restaurantsDetail?.currency;
    const [menusData, setMenusData] = useState([]);
    useEffect(() => {
        let menuData = [];
        if (LikedMenu.length > 0) {
            LikedMenu.forEach((data) => {
                menuData.push({
                    id: data.id,
                    src: data.images?.[0],
                    title: data.name,
                    desc: data.comments,
                    price: data?.sizes?.[0].price,
                    currency: currency,
                    menuDetail: data,
                });
            });
        }

        setMenusData(menuData);
    }, [LikedMenu]);
    useEffect(() => {
        // dispatch(getRestaurantsAction(user?.restaurantID));
        dispatch(getLikedItems(uid));
    }, []);

    return (
        <Container className="liked_items">
            {uid ? (
                <>
                    <MainNavigation title={"Liked Items"} />
                    <CardItem data={menusData} title={"Liked Items"} />
                    <Row className="py-3 justify-content-end mx-5"></Row>
                </>
            ) : (
                <>
                    <MainNavigation title={"Login to view your liked items"} />
                    <Row className="py-5 justify-content-end mx-5"></Row>
                </>
            )}
        </Container>
    );
};

export default LikedItems;
