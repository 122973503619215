/* eslint-disable default-case */
import { useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Auth from "./layout/Auth";
import Main from "./layout/Main";
import routes from "./routes";

function App() {
    const { uid } = useSelector((state) => state.authUser);

    return (
        <>
            <BrowserRouter>
                <Switch>
                    {}
                    {routes.map((route, index) => {
                        if (uid && route.layout === "auth") {
                            return (
                                <Redirect
                                    exact
                                    from={route.path}
                                    to="/restaurants"
                                    key={index}
                                />
                            );
                        }
                        switch (route.layout) {
                            case "main":
                                return (
                                    <Route key={index} exact path={route.path}>
                                        <Main>
                                            <route.component />
                                        </Main>
                                    </Route>
                                );
                            case "auth":
                                return (
                                    <Route key={index} exact path={route.path}>
                                        <Auth>
                                            <route.component />
                                        </Auth>
                                    </Route>
                                );
                        }
                    })}
                    <Redirect to="/signin" />
                </Switch>
            </BrowserRouter>
        </>
    );
}

export default App;
