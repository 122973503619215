import firebase from "../../config/firebase";
import { toast } from "react-toastify";
export const getParentMenus = (id) => async (dispatch) => {
    dispatch(menuLoader(true));
    try {
        await firebase
            .firestore()
            .collection("parentMenus")
            .where("restaurantID", "==", id)
            .where("isDeleted", "==", false)
            .where("isVisible", "==", true)
            .orderBy("position", "asc")
            .onSnapshot(async (querySnapshot) => {
                let parentMenus = [];
                for (let doc of querySnapshot.docs) {
                    const parentMenuData = doc.data();
                    const categoriesQuerySnapshot = await firebase
                        .firestore()
                        .collection("categories")
                        .where("menuID", "==", doc.id)
                        .where("isDeleted", "==", false)
                        .where("isVisible", "==", true)
                        .get();
                    const categories = categoriesQuerySnapshot.docs.map(
                        (category) => category.data().categoryName
                    );
                    parentMenus.push({
                        id: doc.id,
                        ...parentMenuData,
                        categories: categories,
                    });
                }
                dispatch({
                    type: "PARENT_MENUS",
                    payload: parentMenus,
                });
                dispatch(menuLoader(false));
            });
    } catch (error) {
        dispatch(menuLoader(false));
        console.log(error);
    }
};

export const getMenuCategories = (id) => async (dispatch) => {
    dispatch(categoriesLoader(true));
    dispatch(menuLoader(true));
    await firebase
        .firestore()
        .collection("categories")
        .where("menuID", "==", id)
        .where("isDeleted", "==", false)
        .where("isVisible", "==", true)
        .onSnapshot(async (query) => {
            let menuCategories = [];
            for (let doc of query.docs) {
                if (doc) {
                    menuCategories.push({ id: doc.id, ...doc.data() });
                }
            }
            dispatch(categoriesLoader(false));
            await dispatch({
                type: "MENU_CATEGORIES",
                payload: menuCategories,
            });
        });
};

export const getMenuItems = (id, categoryID) => async (dispatch) => {
    dispatch(menuLoader(true));
    try {
        firebase
            .firestore()
            .collection("menus")
            .where("restaurantID", "==", id)
            .where("categoriesID", "==", categoryID)
            .where("isDeleted", "==", false)
            .where("isVisible", "==", true)
            .onSnapshot(async (query) => {
                let menuItem = [];
                if (!query.empty) {
                    for (let doc of query.docs) {
                        const label = doc.data().label;
                        let labelsData = [];
                        label.forEach((item) =>
                            firebase
                                .firestore()
                                .collection("labels")
                                .doc(item.id)
                                .get()
                                .then((labelDoc) => {
                                    let labData = labelDoc.data();
                                    labelsData.push({
                                        labelName: labData?.labelName,
                                        labelURL: labData?.labelURL,
                                    });
                                })
                        );
                        menuItem.push({
                            id: doc.id,
                            ...doc.data(),
                            label: labelsData,
                        });
                    }
                }
                dispatch(menuLoader(false));
                dispatch({ type: "MENU_ITEMS", payload: menuItem });
            });
    } catch (err) {
        toast.error(err.message);
    }
};

export const menuLoader = (val) => async (dispatch) => {
    dispatch({
        type: "MENU_LOADER",
        payload: val,
    });
};
export const categoriesLoader = (val) => async (dispatch) => {
    dispatch({
        type: "CATEGORIES_LOADER",
        payload: val,
    });
};
