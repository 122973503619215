import React from "react";
import { Container } from "reactstrap";
import profile from "../assets/img/profile.svg";

import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import HeroSectionCard from "../components/HeroSectionCard";

const slideImages = [
    "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
];

const HeroSection = () => {
    const { darkMode } = useSelector((state) => state.global);
    const { restaurantsDetail } = useSelector((state) => state.restaurants);

    return (
        <Container>
            <div className="d-flex mb-3 mr-2 pt-3">
                <Avatar
                    size="50"
                    round="100px"
                    alt=""
                    src={restaurantsDetail?.restaurantLogo || profile}
                    width={50}
                />
                <div className="mx-2">
                    <p
                        className={
                            darkMode === true
                                ? "text-white mb-0  fs--16 fw--500"
                                : "mb-0  fs--16 fw--500"
                        }
                    >
                        Welcome to
                    </p>
                    <h5
                        className={
                            darkMode
                                ? "text-white mb-0 fs--22 fw--600"
                                : "mb-0 fs--22 fw--600"
                        }
                    >
                        {restaurantsDetail?.name}
                    </h5>
                </div>
            </div>
            <HeroSectionCard
                slideImages={restaurantsDetail?.restaurantImages || slideImages}
            />
            {}
        </Container>
    );
};

export default HeroSection;
