const initialState = {
    darkMode: localStorage.getItem("darkMode")
        ? JSON.parse(localStorage.getItem("darkMode"))
        : false,
    color: localStorage.getItem("mainColor")
        ? JSON.parse(localStorage.getItem("mainColor"))
        : "#d70365",
    favorite: false,
    singleProduct: {},
};

export default function globalReducer(state = initialState, { type, payload }) {
    switch (type) {
        case "DARKMODE":
            localStorage.setItem("darkMode", JSON.stringify(payload));
            return {
                ...state,
                darkMode: payload,
            };
        case "COLOR":
            localStorage.setItem("mainColor", JSON.stringify(payload));
            return {
                ...state,
                color: payload,
            };
        case "FAVORITE":
            return {
                ...state,
                favorite: payload,
            };
        case "PRODUCT_DETAILS":
            return {
                ...state,
                singleProduct: payload,
            };
        default:
            return state;
    }
}
