import { useSelector } from "react-redux";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Auth = (props) => {
    const location = useLocation();
    const { darkMode } = useSelector((state) => state.global);
    const showNavbar = location.pathname == "/signin";
    return (
        <>
            <div
                className={
                    darkMode === true ? "bg-site-black" : "bg-site-white"
                }
            >
                {!showNavbar ? <Header /> : <div></div>}
                {props.children}
                <Footer />
            </div>
        </>
    );
};

export default Auth;
