import firebase from "../../config/firebase";

export const darkModeToggle = (payload) => async (dispatch) => {
    dispatch({
        type: "DARKMODE",
        payload: payload,
    });
};
export const colorToggle = (payload) => async (dispatch) => {
    dispatch({
        type: "COLOR",
        payload: payload,
    });
};

export const checkLiked = (menuID, customerID) => async (dispatch) => {
    firebase
        .firestore()
        .collection("likedItems")
        .where("menuID", "==", menuID)
        .where("customerID", "==", customerID)
        .onSnapshot(async (query) => {
            if (query.docs.length > 0) {
                dispatch({
                    type: "FAVORITE",
                    payload: true,
                });
            } else {
                dispatch({
                    type: "FAVORITE",
                    payload: false,
                });
            }
        });
};
export const getProduct =
    (id, onSuccess = () => {}) =>
    async (dispatch) => {
        try {
            const doc = await firebase
                .firestore()
                .collection("menus")
                .doc(id)
                .get();
            let recommended = doc.data().recommendedProducts;
            let updatedLabels = [];
            let restaurantID = doc.data().restaurantID;

            const newRecommended = [];

            await Promise.all(
                recommended.map(async (item) => {
                    if (item?.selectedOptions) {
                        for (const option of item.selectedOptions) {
                            const query = await firebase
                                .firestore()
                                .collection("menus")
                                .doc(option.id)
                                .get();
                            let data = query.data();
                            newRecommended.push({ id: query.id, data: data });
                        }
                    }
                })
            );

            const data = await firebase
                .firestore()
                .collection("users")
                .doc(restaurantID)
                .get();
            let currencyData = data.data();

            for (const label of doc.data().label) {
                const query = await firebase
                    .firestore()
                    .collection("labels")
                    .doc(label?.id)
                    .get();
                updatedLabels.push({
                    labelName: query.data()?.labelName,
                    labelURL: query.data()?.labelURL,
                });
            }
            dispatch({
                type: "PRODUCT_DETAILS",
                payload: {
                    id: doc.id,
                    ...doc.data(),
                    label: updatedLabels,
                    currency: currencyData.currency,
                    recommendedProducts: newRecommended,
                },
            });
            onSuccess();
        } catch (error) {
            onSuccess();
            console.error("Error fetching data:", error);
        }
    };

