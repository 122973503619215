import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    Col,
    Container,
    Row,
    Spinner,
    UncontrolledTooltip,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clock from "../assets/img/clock.svg";
import defaultImg from "../assets/img/Mpink.svg";
import { addToCartAction } from "../store/actions/cartAction";
import { toast } from "react-toastify";

const CardItem = ({ data, title, type }) => {
    const history = useHistory();
    const { menuLoading } = useSelector((state) => state.parentMenu);
    const [searchTerm, setSearchTerm] = useState("");
    const dispatch = useDispatch();
    const [menuData, setMenuData] = useState(data);
    const addCartData = (item) => {
        let smallSize = item?.menuDetail?.sizes.sort(
            (a, b) => a.price - b.price
        )[0];
        let obj = {
            name: item?.menuDetail?.name,
            menuItemID: item?.menuDetail?.id,
            images: item?.menuDetail?.images[0] || "",
            quantity: 1,
            price: Number(smallSize?.price),
            totalPrice: Number(smallSize?.price),
            size: smallSize?.subItem,
            currency: item?.currency,
        };

        dispatch(
            addToCartAction(obj, () => {
                toast.success("Added to Cart");
            })
        );
    };
    function searchMenus(searchTerm) {
        const foundMenus = data.filter(
            (f) =>
                f.title &&
                f.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return foundMenus;
    }

    const handleChange = (value) => {
        setSearchTerm(value);
    };
    useEffect(() => {
        if (searchTerm !== "") {
            const filteredMenu = searchMenus(searchTerm);
            setMenuData(filteredMenu);
        } else {
            setMenuData(data);
        }
    }, [searchTerm, data]);
    const { darkMode, color } = useSelector((state) => state.global);
    return (
        <Container className="most_popular">
            <Row className=" mt-3 d-flex justify-content-between align-items-center">
                <Col lg={8} md={6}>
                    <h4
                        className={
                            darkMode
                                ? "fs--18 fw--600 mb-0  normal-heading-dark"
                                : "fs--18 fw--600 mb-0  normal-heading"
                        }
                    >
                        {title}
                    </h4>
                </Col>
                <Col lg={4} md={6} className="d-flex align-items-center">
                    <div class="input-group mb-2 border rounded-pill py-1 px-2 w-75 bg-light">
                        <input
                            type="search"
                            value={searchTerm}
                            onChange={(e) => handleChange(e.target.value)}
                            placeholder="What're you searching for?"
                            class="fs--14 form-control bg-light border-0"
                        />
                    </div>
                    {title !== "Liked Items" &&
                        menuData?.length > 0 &&
                        type !== "all" && (
                            <h6
                                className="mb-0 fs--14 most_popular__view-all ml-2"
                                style={{ color: `${color}` }}
                                onClick={() => {
                                    history.push({
                                        pathname: "/viewall-product",
                                        state: menuData,
                                    });
                                }}
                            >
                                View All
                            </h6>
                        )}
                </Col>
            </Row>
            {menuData?.length > 0 ? (
                <>
                    {menuLoading ? (
                        <Row className="py-5 justify-content-center position-relative">
                            <Spinner color="primary" />
                        </Row>
                    ) : (
                        <Row className="mt-2 justify-content-center position-relative">
                            {menuData.length > 0 &&
                                // eslint-disable-next-line array-callback-return
                                menuData?.map((item, index) => {
                                    const label = item?.label?.slice(0, 2);
                                    if (type === "all") {
                                        return (
                                            <Col
                                                key={index}
                                                className={
                                                    darkMode
                                                        ? " d-flex most_popular__card-dark cursor-pointer"
                                                        : " d-flex most_popular__card cursor-pointer"
                                                }
                                                md={4}
                                                sm={4}
                                                lg={4}
                                                xl={3}
                                                xs={6}
                                            >
                                                <Card
                                                    className="p-1 m-2"
                                                    key={item.id}
                                                >
                                                    <img
                                                        alt="Sample"
                                                        className="product_profile"
                                                        src={
                                                            item?.src
                                                                ? item.src
                                                                : defaultImg
                                                        }
                                                        onClick={() =>
                                                            history.push({
                                                                pathname: `/productDetails/${item.id}`,
                                                                state: {
                                                                    ...item?.menuDetail,
                                                                    currency:
                                                                        item?.currency,
                                                                },
                                                            })
                                                        }
                                                    />
                                                    <CardBody
                                                        className="px-1"
                                                        onClick={() =>
                                                            history.push({
                                                                pathname: `/productDetails/${item.id}`,
                                                                state: {
                                                                    ...item?.menuDetail,
                                                                    currency:
                                                                        item?.currency,
                                                                },
                                                            })
                                                        }
                                                    >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <CardTitle
                                                                tag="h5"
                                                                className="mb-2 fs--16 fw--700 title text-capitalize"
                                                            >
                                                                {item?.title}
                                                            </CardTitle>
                                                            {label?.map(
                                                                (itm, idx) => {
                                                                    return (
                                                                        <React.Fragment
                                                                            key={
                                                                                idx
                                                                            }
                                                                        >
                                                                            <img
                                                                                alt="item"
                                                                                key={
                                                                                    itm.id
                                                                                }
                                                                                id={`suggestion_${idx}`}
                                                                                className=" mr-3"
                                                                                src={
                                                                                    itm?.labelURL
                                                                                }
                                                                                width={
                                                                                    25
                                                                                }
                                                                                height={
                                                                                    25
                                                                                }
                                                                            />
                                                                            <UncontrolledTooltip
                                                                                placement="below"
                                                                                target={`#suggestion_${idx}`}
                                                                                autohide={
                                                                                    false
                                                                                }
                                                                                className="LabelCss"
                                                                            >
                                                                                {
                                                                                    itm.labelName
                                                                                }
                                                                            </UncontrolledTooltip>
                                                                        </React.Fragment>
                                                                    );
                                                                }
                                                            )}
                                                            {}
                                                        </div>
                                                        <CardSubtitle className=" d-flex justify-content-between">
                                                            <div
                                                                className="d-flex align-items-center"
                                                                title={`Cooking Time ${item?.menuDetail?.estimatedTime}`}
                                                            >
                                                                <img
                                                                    alt="clock"
                                                                    src={clock}
                                                                    width={10}
                                                                    height={10}
                                                                    className={`mr-1 ${
                                                                        darkMode
                                                                            ? "helper-text-dark"
                                                                            : "helper-text"
                                                                    }`}
                                                                />
                                                                <h6
                                                                    className={`mb-0 fs--10 f fw--400  ${
                                                                        darkMode
                                                                            ? "helper-text-dark"
                                                                            : "helper-text"
                                                                    }`}
                                                                >
                                                                    {
                                                                        item
                                                                            ?.menuDetail
                                                                            ?.estimatedTime
                                                                    }
                                                                    &nbsp; mins
                                                                </h6>
                                                            </div>
                                                            {item?.price && (
                                                                <h6
                                                                    className="mb-0 fs--12 fw--400"
                                                                    style={{
                                                                        color: `${color}`,
                                                                    }}
                                                                >
                                                                    {
                                                                        item?.currency
                                                                    }{" "}
                                                                    {
                                                                        item?.price
                                                                    }
                                                                </h6>
                                                            )}
                                                        </CardSubtitle>
                                                        {item?.calories && (
                                                            <h6
                                                                className={`mb-0 fs--10 f fw--400  ${
                                                                    darkMode
                                                                        ? "helper-text-dark"
                                                                        : "helper-text"
                                                                }`}
                                                            >
                                                                {item?.calories}
                                                            </h6>
                                                        )}
                                                    </CardBody>
                                                    <svg
                                                        width="28"
                                                        height="28"
                                                        viewBox="0 0 28 28"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="plus_icon"
                                                        onClick={() => {
                                                            addCartData(item);
                                                        }}
                                                    >
                                                        <rect
                                                            x="0.5"
                                                            y="0.5"
                                                            width="26.2554"
                                                            height="26.2554"
                                                            rx="13.1277"
                                                            fill={color}
                                                        />
                                                        <path
                                                            d="M12.6727 19.2554V8H14.5827V19.2554H12.6727ZM8 14.5827V12.6727H19.2554V14.5827H8Z"
                                                            fill="white"
                                                        />
                                                        <rect
                                                            x="0.5"
                                                            y="0.5"
                                                            width="26.2554"
                                                            height="26.2554"
                                                            rx="13.1277"
                                                            stroke="white"
                                                        />
                                                    </svg>
                                                </Card>
                                            </Col>
                                        );
                                    } else {
                                        if (index < 4) {
                                            return (
                                                <Col
                                                    key={index}
                                                    className={
                                                        darkMode
                                                            ? " d-flex most_popular__card-dark cursor-pointer"
                                                            : " d-flex most_popular__card cursor-pointer"
                                                    }
                                                    md={4}
                                                    sm={4}
                                                    lg={4}
                                                    xl={3}
                                                    xs={6}
                                                >
                                                    <Card
                                                        className="p-1 m-2"
                                                        key={item.id}
                                                    >
                                                        <img
                                                            alt="Sample"
                                                            className="product_profile"
                                                            src={
                                                                item?.src
                                                                    ? item.src
                                                                    : defaultImg
                                                            }
                                                            onClick={() =>
                                                                history.push({
                                                                    pathname: `/productDetails/${item.id}`,
                                                                    state: {
                                                                        ...item?.menuDetail,
                                                                        currency:
                                                                            item?.currency,
                                                                    },
                                                                })
                                                            }
                                                        />
                                                        <CardBody
                                                            className="px-1"
                                                            onClick={() =>
                                                                history.push({
                                                                    pathname: `/productDetails/${item.id}`,
                                                                    state: {
                                                                        ...item?.menuDetail,
                                                                        currency:
                                                                            item?.currency,
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            <div className="d-flex mb-1 justify-content-between align-items-center">
                                                                <CardTitle
                                                                    tag="h5"
                                                                    className="mb-2 fs--16 fw--700 title text-capitalize"
                                                                >
                                                                    {
                                                                        item?.title
                                                                    }
                                                                </CardTitle>
                                                                <div className="d-flex justify-content-between align-items-center mb-2">
                                                                    {label?.map(
                                                                        (
                                                                            itm,
                                                                            idx
                                                                        ) => {
                                                                            return (
                                                                                <React.Fragment
                                                                                    key={
                                                                                        idx
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        alt="item"
                                                                                        key={
                                                                                            itm.id
                                                                                        }
                                                                                        id={`suggestion_${idx}`}
                                                                                        className=" mr-3"
                                                                                        src={
                                                                                            itm?.labelURL
                                                                                        }
                                                                                        width={
                                                                                            25
                                                                                        }
                                                                                        height={
                                                                                            25
                                                                                        }
                                                                                    />
                                                                                    <UncontrolledTooltip
                                                                                        placement="below"
                                                                                        target={`#suggestion_${idx}`}
                                                                                        autohide={
                                                                                            false
                                                                                        }
                                                                                        className="LabelCss"
                                                                                    >
                                                                                        {
                                                                                            itm.labelName
                                                                                        }
                                                                                    </UncontrolledTooltip>
                                                                                </React.Fragment>
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                                {}
                                                            </div>
                                                            <CardSubtitle className=" d-flex justify-content-between ">
                                                                <div
                                                                    className="d-flex align-items-center"
                                                                    title={`Cooking Time ${item?.menuDetail?.estimatedTime}`}
                                                                >
                                                                    <img
                                                                        alt="CardItem"
                                                                        src={
                                                                            clock
                                                                        }
                                                                        width={
                                                                            15
                                                                        }
                                                                        height={
                                                                            15
                                                                        }
                                                                        className={`mr-1 ${
                                                                            darkMode
                                                                                ? "helper-text-dark"
                                                                                : "helper-text"
                                                                        }`}
                                                                    />
                                                                    <h6
                                                                        className={`mb-0 fs--10 f fw--400  ${
                                                                            darkMode
                                                                                ? "helper-text-dark"
                                                                                : "helper-text"
                                                                        }`}
                                                                    >
                                                                        {
                                                                            item
                                                                                ?.menuDetail
                                                                                ?.estimatedTime
                                                                        }
                                                                        &nbsp;
                                                                        mins
                                                                    </h6>
                                                                </div>
                                                                {item?.price && (
                                                                    <h6
                                                                        className="mb-0 fs--12 fw--400"
                                                                        style={{
                                                                            color: `${color}`,
                                                                        }}
                                                                    >
                                                                        {
                                                                            item?.currency
                                                                        }{" "}
                                                                        &nbsp;
                                                                        {
                                                                            item?.price
                                                                        }
                                                                    </h6>
                                                                )}
                                                            </CardSubtitle>
                                                            {item?.calories && (
                                                                <h6
                                                                    className={`mb-0 fs--10 f fw--400  ${
                                                                        darkMode
                                                                            ? "helper-text-dark"
                                                                            : "helper-text"
                                                                    }`}
                                                                >
                                                                    {
                                                                        item?.calories
                                                                    }
                                                                </h6>
                                                            )}
                                                        </CardBody>
                                                        <svg
                                                            width="28"
                                                            height="28"
                                                            viewBox="0 0 28 28"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="plus_icon"
                                                            onClick={() => {
                                                                addCartData(
                                                                    item
                                                                );
                                                            }}
                                                        >
                                                            <rect
                                                                x="0.5"
                                                                y="0.5"
                                                                width="26.2554"
                                                                height="26.2554"
                                                                rx="13.1277"
                                                                fill={color}
                                                            />
                                                            <path
                                                                d="M12.6727 19.2554V8H14.5827V19.2554H12.6727ZM8 14.5827V12.6727H19.2554V14.5827H8Z"
                                                                fill="white"
                                                            />
                                                            <rect
                                                                x="0.5"
                                                                y="0.5"
                                                                width="26.2554"
                                                                height="26.2554"
                                                                rx="13.1277"
                                                                stroke="white"
                                                            />
                                                        </svg>
                                                    </Card>
                                                </Col>
                                            );
                                        }
                                    }
                                })}
                        </Row>
                    )}
                </>
            ) : (
                <>
                    <Row className="mt-2 justify-content-center position-relative">
                        <h6
                            className={`mb-0 fs--16 f fw--800 py-5  ${
                                darkMode ? "helper-text-dark" : "helper-text"
                            }`}
                        >
                            {title !== "Liked Items"
                                ? "No Product Exist against this Category"
                                : "You have not liked any items"}
                        </h6>
                    </Row>
                </>
            )}
        </Container>
    );
};

export default CardItem;
