import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, Row, Spinner } from "reactstrap";
import {toast} from 'react-toastify';
import TextField from "@mui/material/TextField";
import {
    Avatar,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@mui/styles";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import MainNavigation from "../../components/MainNavigation";
import { FaUser } from "react-icons/fa";
import {
    getSingleUser,
    updateProfileAction,
    updateProfileImageAction,
} from "../../store/actions/authAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ProfileDetails = () => {
    const outerTheme = useTheme();
    const history = useHistory();
    const { darkMode, color } = useSelector((state) => state.global);
    const { uid } = useSelector((state) => state.authUser);
    const { loading, singleUser } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(getSingleUser(uid));
    }, [uid]);
    const [image, setImage] = useState("");
    const [age, setAge] = useState("");
    const [ImageURL, setImageURL] = useState("");
    const [userData, setUserData] = useState({
        contactNumber: "",
        name: "",
        email: "",
        address: "",
        gender: "",
        DOB: "",
    });

    function calculateAge(dateOfBirth) {
        var dateParts = dateOfBirth.split("-");
        var day = parseInt(dateParts[2]);
        var month = parseInt(dateParts[1]) - 1;
        var year = parseInt(dateParts[0]);

        var birthday = new Date(year, month, day);
        if (isNaN(birthday.getTime())) {
            return null;
        }

        var today = new Date();
        var age = today.getFullYear() - birthday.getFullYear();
        var monthDifference = today.getMonth() - birthday.getMonth();
        if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthday.getDate())
        ) {
            age--;
        }
        return age;
    }

    const handleAge = (e) => {
        const ageValue = calculateAge(e);
        if (ageValue) {
            setUserData((obj) => ({
                ...obj,
                ["age"]: Number(ageValue),
            }));
        }
    };
    useEffect(() => {
        if (singleUser) {
            setUserData({
                contactNumber: singleUser?.contactNumber,
                name: singleUser?.name,
                email: singleUser?.email,
                address: singleUser?.address,
                id: singleUser?.id,
                gender: singleUser?.gender,
                DOB: singleUser?.DOB,
            });
            setImageURL(singleUser?.imageURL);
        }
    }, [singleUser]);

    const updateImageProfile = (e) => {
        const file = e.target.files[0];

        if (file && file.type.startsWith("image/")) {
            setImage(file);
            let obj = {
                image: file,
                id: singleUser?.id,
            };
            dispatch(updateProfileImageAction(obj));
        } else {
            toast.warning("Please select a valid image file.");
        }
    };
    const handleChangeUser = (e) => {
        if (e.target.name == "DOB") {
            setAge(e.target.value);
            handleAge(e.target.value);
            setUserData((obj) => ({
                ...obj,
                [e.target.name]: e.target.value,
            }));
        } else {
            setUserData((obj) => ({
                ...obj,
                [e.target.name]: e.target.value,
            }));
        }
    };
    const imgRef = useRef(null);

    const theme = createTheme({
        palette: {
            secondary: {
                main: `${color ? color : "#000000"}`,
            },
        },
    });
    const inputProps = {
        className: darkMode
            ? "my-notched-outline-dark"
            : "my-notched-outline-light",
    };

    const labelProps = {
        style: {
            color: darkMode ? "#ffffff" : "rgb(101 92 92)",
        },
    };

    const inputColor = darkMode ? "#ffffff" : "rgb(101 92 92)";
    const CustomTextField = withStyles({
        root: {
            "& fieldset": {
                borderColor: darkMode ? "#95989a" : "rgba(0, 0, 0, 0.125)",
            },
        },
    })(TextField);

    const customTheme = (outerTheme) =>
        createTheme({
            palette: {
                mode: outerTheme.palette.mode,
            },
            components: {
                MuiOutlinedInput: {
                    styleOverrides: {
                        notchedOutline: {
                            borderColor: inputColor,
                        },
                        root: {
                            [`&:hover .${outlinedInputClasses.notchedOutline}`]:
                                {
                                    borderColor: "000000",
                                },
                            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]:
                                {
                                    borderColor: `${color || "#000000"}`,
                                },
                        },
                    },
                },
            },
        });

    const dispatch = useDispatch();

    return (
        <div className="auth">
            <Container className="py-4">
                <MainNavigation title={"My Details"} />

                <Row className="d-flex flex-column justify-content-center align-items-center">
                    <Col className="d-flex mt-3 justify-content-center align-items-center">
                        <Card
                            className={
                                darkMode ? "auth__card-dark" : "auth__card"
                            }
                        >
                            <Form
                                className="px-3 "
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    dispatch(
                                        updateProfileAction(userData, () => {
                                            history.push("/restaurants");
                                        })
                                    );
                                }}
                            >
                                <ThemeProvider theme={theme}>
                                    <Row className="py-4 justify-content-center ">
                                        <div className="rounded-circle position-relative">
                                            <Avatar
                                                style={{
                                                    minHeight: "90px",
                                                    minWidth: "90px",
                                                }}
                                                className="img-fluid"
                                                src={
                                                    // userData?.imageURL ||
                                                    (image &&
                                                        URL?.createObjectURL(
                                                            image
                                                        )) ||
                                                    ImageURL || (
                                                        // avatar
                                                        <FaUser className="fs--20" />
                                                    )
                                                }
                                            />
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="27"
                                                height="27"
                                                viewBox="0 0 27 27"
                                                fill="none"
                                                className="image_upload"
                                                onClick={() =>
                                                    imgRef.current.click()
                                                }
                                            >
                                                <circle
                                                    cx="13.5"
                                                    cy="13.5"
                                                    r="12.5"
                                                    style={{ fill: `${color}` }}
                                                    stroke="#EEEEEE"
                                                    strokeWidth="2"
                                                />
                                                <g clipPath="url(#clip0_908_21160)">
                                                    <path
                                                        d="M18.125 10.7598H17.0553L16.6517 9.95249C16.332 9.31316 15.6894 8.91602 14.9746 8.91602H13.0254C12.3106 8.91602 11.6679 9.31316 11.3483 9.95249L10.9446 10.7598H9.875C8.84113 10.7598 8 11.6009 8 12.6347V17.209C8 18.2429 8.84113 19.084 9.875 19.084H18.125C19.1589 19.084 20 18.2429 20 17.209V12.6347C20 11.6009 19.1589 10.7598 18.125 10.7598ZM19.0625 17.209C19.0625 17.7259 18.6419 18.1465 18.125 18.1465H9.875C9.35806 18.1465 8.9375 17.7259 8.9375 17.209V12.6347C8.9375 12.1178 9.35806 11.6972 9.875 11.6972H11.2344C11.4119 11.6972 11.5742 11.5969 11.6536 11.4381L12.1869 10.3717C12.3467 10.0521 12.668 9.85349 13.0254 9.85349H14.9746C15.332 9.85349 15.6534 10.0521 15.8132 10.3717L16.3464 11.4381C16.3853 11.516 16.4451 11.5815 16.5192 11.6272C16.5932 11.673 16.6786 11.6973 16.7656 11.6972H18.125C18.6419 11.6972 19.0625 12.1178 19.0625 12.6347V17.209ZM14 12.1348C12.7249 12.1348 11.6875 13.1721 11.6875 14.4473C11.6875 15.7224 12.7249 16.7598 14 16.7598C15.2751 16.7598 16.3125 15.7224 16.3125 14.4473C16.3125 13.1721 15.2751 12.1348 14 12.1348ZM14 15.8223C13.2418 15.8223 12.625 15.2054 12.625 14.4472C12.625 13.6891 13.2418 13.0722 14 13.0722C14.7582 13.0722 15.375 13.6891 15.375 14.4472C15.375 15.2054 14.7582 15.8223 14 15.8223Z"
                                                        fill="white"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_908_21160">
                                                        <rect
                                                            width="12"
                                                            height="12"
                                                            fill="white"
                                                            transform="translate(8 8)"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </Row>
                                    <input
                                        type="file"
                                        name="imageURL"
                                        ref={imgRef}
                                        onChange={(e) => {
                                            updateImageProfile(e);
                                        }}
                                        hidden
                                    ></input>

                                    <CustomTextField
                                        id="outlined-required"
                                        label="Full Name"
                                        fullWidth
                                        className="mt-4 mb-3"
                                        type="text"
                                        defaultValue={userData.name}
                                        color="secondary"
                                        name="name"
                                        onBlur={(e) => {
                                            handleChangeUser(e);
                                        }}
                                        inputProps={inputProps}
                                        InputLabelProps={labelProps}
                                        InputProps={{
                                            style: {
                                                color: inputColor,
                                            },
                                        }}
                                        variant="outlined"
                                        required
                                    />
                                    <CustomTextField
                                        id="outlined-required"
                                        label="Phone No."
                                        fullWidth
                                        className="mb-3"
                                        name="contactNumber"
                                        defaultValue={userData.contactNumber}
                                        onBlur={(e) => {
                                            handleChangeUser(e);
                                        }}
                                        type="number"
                                        color="secondary"
                                        inputProps={inputProps}
                                        InputLabelProps={labelProps}
                                        InputProps={{
                                            style: {
                                                color: inputColor,
                                            },
                                        }}
                                        variant="outlined"
                                        required
                                    />
                                    <CustomTextField
                                        id="outlined-required"
                                        label="Email"
                                        fullWidth
                                        className="mb-3"
                                        name="email"
                                        defaultValue={userData.email}
                                        onBlur={(e) => {
                                            handleChangeUser(e);
                                        }}
                                        type="email"
                                        color="secondary"
                                        inputProps={inputProps}
                                        InputLabelProps={labelProps}
                                        InputProps={{
                                            style: {
                                                color: inputColor,
                                            },
                                        }}
                                        required
                                        variant="outlined"
                                    />
                                    <CustomTextField
                                        id="outlined-required"
                                        label="Address"
                                        fullWidth
                                        className="mb-3"
                                        type="text"
                                        name="address"
                                        defaultValue={userData.address}
                                        onBlur={(e) => {
                                            handleChangeUser(e);
                                        }}
                                        color="secondary"
                                        inputProps={inputProps}
                                        InputLabelProps={labelProps}
                                        InputProps={{
                                            style: {
                                                color: inputColor,
                                            },
                                        }}
                                        required
                                        variant="outlined"
                                    />
                                    <CustomTextField
                                        id="outlined-required"
                                        // label="DOB"
                                        fullWidth
                                        className="mb-3"
                                        type="date"
                                        name="DOB"
                                        placeholder="mm/dd/yyyy"
                                        defaultValue={userData.DOB}
                                        onBlur={(e) => {
                                            handleChangeUser(e);
                                        }}
                                        color="secondary"
                                        inputProps={{
                                            max: new Date(new Date().getFullYear() - 2, 4, 31) // Set December 31st of the previous year
                                            .toISOString()
                                            .split("T")[0], // Set the max attribute to today's date
                                            ...inputProps,
                                        }}
                                        InputLabelProps={labelProps}
                                        InputProps={{
                                            style: {
                                                color: inputColor,
                                            },
                                        }}
                                        variant="outlined"
                                        required
                                    />
                                </ThemeProvider>
                                <ThemeProvider theme={customTheme(outerTheme)}>
                                    <FormControl required fullWidth>
                                        <InputLabel
                                            style={{
                                                color: darkMode
                                                    ? "#ffffff"
                                                    : "rgb(101 92 92)",
                                            }}
                                            id="demo-simple-select-required-label"
                                        >
                                            Gender
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="demo-simple-select-required-label"
                                            id="demo-simple-select-required"
                                            className="mb-4"
                                            label="Gender *"
                                            name="gender"
                                            value={userData.gender}
                                            onChange={(e) =>
                                                handleChangeUser(e)
                                            }
                                            color="secondary"
                                            style={{ color: inputColor }}
                                            inputProps={inputProps}
                                            InputLabelProps={labelProps}
                                            InputProps={{
                                                style: {
                                                    color: inputColor,
                                                },
                                            }}
                                            required
                                            variant="outlined"
                                        >
                                            <MenuItem value="male">
                                                Male
                                            </MenuItem>
                                            <MenuItem value="female">
                                                Female
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Row className="d-flex justify-content-center p-3">
                                        <Button
                                            className="top_cards__addtocart fs--16 fw--400 my-4"
                                            style={{ background: `${color}` }}
                                            type="submit"
                                            disabled={loading}
                                        >
                                            {loading ? (
                                                <Spinner size="md" />
                                            ) : (
                                                `Confirm`
                                            )}
                                        </Button>
                                    </Row>
                                </ThemeProvider>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ProfileDetails;
