import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Row } from "reactstrap";

const PricingSection = ({ data }) => {
    const location = useLocation();
    const pathName = location.pathname;
    const { darkMode, color } = useSelector((state) => state.global);

    return (
        <Row
            className={`d-flex flex-column my-4  px-4 ${
                pathName == "/tracking" && darkMode
                    ? "bg-transparent mt-3"
                    : darkMode
                    ? "bg-site-black mt-3"
                    : "bg-site-white mt-3"
            }`}
        >
            <div className="d-flex py-3 justify-content-between align-items-center cart__shipping_pricing">
                <h5
                    className={
                        darkMode ? " normal-heading-dark" : "price_heading"
                    }
                >
                    Price
                </h5>
                <h5
                    className={
                        darkMode
                            ? "fs--18 fw--600 normal-heading-dark "
                            : "fs--18 fw--600 "
                    }
                >
                    {data?.currency} {data?.price}
                </h5>
            </div>
            {}
            <div className="d-flex my-2 justify-content-between align-items-center cart__shipping_pricing">
                <h5
                    className={
                        darkMode ? " normal-heading-dark" : "price_heading"
                    }
                >
                    Tip
                </h5>
                <h5
                    className={
                        darkMode
                            ? "fs--18 fw--600 normal-heading-dark "
                            : "fs--18 fw--600 "
                    }
                >
                    {data?.currency} {data?.tip ? data.tip?.toFixed(2) : 0}
                </h5>
            </div>
            <div className="d-flex my-2 justify-content-between align-items-center cart__shipping_pricing">
                <h5
                    className={
                        darkMode ? " normal-heading-dark" : "price_heading"
                    }
                >
                    Total
                </h5>
                <h5
                    className={
                        darkMode
                            ? "fs--18 fw--600 normal-heading-dark "
                            : "fs--18 fw--600 "
                    }
                >
                    {data?.currency} {data?.total ? data?.total?.toFixed(2) : 0}
                </h5>
            </div>
        </Row>
    );
};

export default PricingSection;
