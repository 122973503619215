import React, { useEffect, useState } from "react";
import {
    CardElement,
    Elements,
    ElementsConsumer,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import axios from "axios";

import { loadStripe } from "@stripe/stripe-js";
import { TEST_CLIENT_KEY } from "../../contants/index.js";
import { FaCreditCard, FaCalendarAlt } from "react-icons/fa";

import {
    Label,
    Button,
    Container,
    Row,
    Col,
    Spinner,
    Form,
    FormGroup,
    Card,
    Input,
} from "reactstrap";
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from "@stripe/react-stripe-js";
import {
    addOrderAction,
    getPaymentMethodAction,
    updateMethodAction,
} from "../../store/actions/orderAction.js";
import { clearCartAction } from "../../store/actions/cartAction.js";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function UpdateStripe({ setOpenStripe, cardID }) {
    const dispatch = useDispatch();
    const { darkMode } = useSelector((state) => state.global);
    let restaurantID = localStorage.getItem("restaurantID");
    let { user, uid } = useSelector((state) => state.authUser);
    const [cardNumber, setCardNumber] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [cvv, setCvv] = useState("");
    const { updatePaymentLoader } = useSelector((state) => state.orders);
    const handleCardNumberChange = (e) => {
        const formattedValue = e.target?.value?.replace(/\D/g, "");
        const finalFormattedValue = formattedValue?.slice(0, 16);
        let formattedCardNumber = "";
        for (let i = 0; i < finalFormattedValue?.length; i++) {
            formattedCardNumber += finalFormattedValue?.charAt(i);
            if ((i + 1) % 4 === 0 && i !== finalFormattedValue?.length - 1) {
                formattedCardNumber += " ";
            }
        }

        setCardNumber(formattedCardNumber);
    };

    const handleExpiryDateChange = (e) => {
        const formattedValue = e.target?.value?.replace(/\D/g, "");
        let finalFormattedValue = "";
        if (formattedValue.length > 2) {
            finalFormattedValue = `${formattedValue.slice(
                0,
                2
            )}/${formattedValue.slice(2, 4)}`;
        } else {
            finalFormattedValue = formattedValue;
        }

        setExpiryDate(finalFormattedValue);
    };

    const handleCvvChange = (e) => {
        const formattedValue = e.target?.value?.replace(/\D/g, "").slice(0, 3);
        setCvv(formattedValue);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        let card = cardNumber?.replace(/\s/g, "");
        if (card?.length !== 16) {
            toast.warning("Card number must be 16 digits");
            return;
        }
        const expiryDatePattern = /^(0[1-9]|1[0-2])\/(\d{2})$/;
        if (!expiryDate?.match(expiryDatePattern)) {
            toast.warning("Expiry date format must be MM/YY");
            return;
        }
        if (cvv?.length !== 3) {
            toast.warning("CVV/CVC must be 3 digits");
            return;
        }
        let body = {
            card: {
                number: card,
                exp_month: expiryDate?.split("/")[0],
                exp_year: expiryDate?.split("/")[1],
                cvc: cvv,
            },
            cardID: cardID,
            customerID: uid,
        };
        dispatch(
            updateMethodAction(body, () => {
                setOpenStripe(false);
                setCardNumber("");
                setCvv("");
                setExpiryDate("");
            })
        );
    };
    return (
        <Card
            className={`py-4 px-3 my-2 ${
                darkMode ? "payment_method__card-dark" : "payment_method__card"
            }`}
        >
            <Form onSubmit={handleSubmit}>
                <h4 className="text-center fw--600 py-2">
                    Modifying Payment Card
                </h4>
                <Row className="align-items-center">
                    <Col md="3" xs="6">
                        <FormGroup>
                            <Label for="cardNumber">Card Number</Label>
                            <Input
                                type="text"
                                required
                                placeholder="1234 1234 1234 1234"
                                id="cardNumber"
                                value={cardNumber}
                                onChange={handleCardNumberChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3" xs="6">
                        <FormGroup>
                            <Label for="expiryDate">Expiry Date</Label>
                            <Input
                                type="text"
                                required
                                id="expiryDate"
                                placeholder="MM/YY"
                                value={expiryDate}
                                onChange={handleExpiryDateChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3" xs="6">
                        <FormGroup>
                            <Label for="cvv">CVC/CVV:</Label>
                            <Input
                                type="text"
                                required
                                id="cvv"
                                placeholder="..."
                                value={cvv}
                                onChange={handleCvvChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3" xs="6">
                        <Button
                            className="auth-button mt-3 py-2 btn btn-md btn-block border border-none"
                            type="submit"
                            style={{
                                backgroundColor: "#f2ba36",
                            }}
                            disabled={updatePaymentLoader}
                        >
                            {updatePaymentLoader ? (
                                <Spinner size="sm" />
                            ) : (
                                <h4 className="mb-0">Update</h4>
                            )}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}

export default UpdateStripe;
