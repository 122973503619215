import React, { useEffect, useMemo, useState } from "react";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, FormGroup, Input, Row, Spinner } from "reactstrap";
import { clearCartAction } from "../store/actions/cartAction";
import {
    addOrderAction,
    getPaymentMethodAction,
} from "../store/actions/orderAction";

const SavedCards = ({ amount, orderData, forwardFees }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    let restaurantID = localStorage.getItem("restaurantID");
    let { user, uid } = useSelector((state) => state.authUser);
    const { darkMode, color } = useSelector((state) => state.global);
    const { paymentCard } = useSelector((state) => state.orders);
    const [cardID, setCardID] = useState();
    const [paymentLoader, setPaymentLoader] = useState(false);
    const { singleUser } = useSelector((state) => state.user);
    useEffect(() => {
        dispatch(getPaymentMethodAction(uid));
    }, []);
    function calculateFinalAmount(amount, type, tax) {
        if (type === "percentage") {
            const percentage = parseFloat(tax) / 100;
            return amount + amount * percentage;
        } else {
            return amount + parseFloat(tax);
        }
    }
    const finalAmount = useMemo(
        () =>
            calculateFinalAmount(
                amount,
                forwardFees?.type ? forwardFees?.type : "",
                forwardFees?.amount ? forwardFees?.amount : 0
            ),
        [amount, forwardFees]
    );

    const paymentData = async (e, card) => {
        e.preventDefault();
        setPaymentLoader(true);
        const body = {
            restaurantID: restaurantID,
            amount: Number(finalAmount),
            email: user?.email,
            card: {
                cvc: card?.cvc,
                exp_month: card?.exp_month,
                exp_year: card?.exp_year,
                number: card?.number,
            },
            currency: orderData?.order[0]?.currency || "USD",
        };

        await axios
            .post(
                "https://us-central1-menux1.cloudfunctions.net/app/payment",
                body
            )
            .then(async (res) => {
                let { data } = res;
                await setPaymentLoader(false);
                let obj = {
                    ...orderData,
                    totalAmount: finalAmount || 0,
                    customerID: uid,
                    paymentMethod: "Stripe",
                    status: "pending",
                    customerName: singleUser?.name,
                    paymentID: data?.charge?.id,
                };
                dispatch(
                    addOrderAction(
                        obj,
                        (orderID) => {
                            toast.success("Order Placed Successfully..!");
                            history.push(`/tracking/orderID=${orderID}`);
                            dispatch(clearCartAction());
                            localStorage.removeItem("cartPath");
                            setPaymentLoader(false);
                        },

                        (res) => {
                            setPaymentLoader(false);
                        }
                    )
                );
            })
            .catch((err) => {
                let { data } = err.response;
                console.error(data?.message, "Error");
                setPaymentLoader(false);
            });
    };
    const handleOptionChange = (value) => {
        if (value === cardID) {
            setCardID("");
        } else {
            setCardID(value);
        }
    };

    return (
        <>
            {paymentCard
                ?.filter(
                    (card, index, self) =>
                        index ===
                        self.findIndex(
                            (c) =>
                                c.number === card.number && c.cvc === card.cvc
                        )
                )
                .map((card) => {
                    return (
                        <Card
                            className={
                                darkMode
                                    ? "p-3 mb-3 cart__payment_card-dark"
                                    : "p-3 mb-3 cart__payment_card"
                            }
                            key={card.id}
                        >
                            <Row
                                className={`m-0 d-flex justify-content-between align-items-center ${
                                    darkMode
                                        ? "payment_method__card-dark_row"
                                        : "payment_method__card_row"
                                }`}
                            >
                                <div className="d-flex justify-content-center align-items-center">
                                    <h5 className=" typograhy mx-3">
                                        {card?.brand}
                                    </h5>
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <h5 className=" typograhy">
                                        {card?.sample}
                                    </h5>
                                </div>

                                <FormGroup check className="radio_box">
                                    <Input
                                        type="radio"
                                        name="radio"
                                        checked={cardID == card?.id}
                                        value={card?.id}
                                        onClick={(e) =>
                                            handleOptionChange(e.target.value)
                                        }
                                        onChange={() => {}}
                                        style={{ "--accent-color": `${color}` }}
                                    />
                                </FormGroup>
                            </Row>
                            {cardID == card?.id && (
                                <>
                                    <Button
                                        className="auth-button mt-2 py-3 btn btn-lg btn-block border border-none"
                                        onClick={(e) => paymentData(e, card)}
                                        style={{ backgroundColor: "#f2ba36" }}
                                        disabled={paymentLoader}
                                    >
                                        {paymentLoader ? (
                                            <Spinner size="sm" />
                                        ) : (
                                            <h4 className="mb-0">Pay</h4>
                                        )}
                                    </Button>
                                </>
                            )}
                        </Card>
                    );
                })}
            {}
        </>
    );
};

export default SavedCards;
