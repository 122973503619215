import React from "react";
import { Col, Container, Row } from "reactstrap";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import discord from "../assets/img/discord.svg";
import fb from "../assets/img/fbVector.svg";
import insta from "../assets/img/insta.svg";
import linkedinVector from "../assets/img/linkedinVector.svg";
import linktree from "../assets/img/linktree.svg";
import logoIpsum from "../assets/img/logoIpsum.svg";
import menuxbrand from "../assets/img/MPink.png";
import menuxbrandLight from "../assets/img/MPink.png";
import snapchat from "../assets/img/snapchat.svg";
import tiktok from "../assets/img/tiktok.svg";
import twiterVector from "../assets/img/twiterVector.svg";
import website from "../assets/img/website.svg";
import youtube from "../assets/img/youtube.svg";

const Footer = () => {
    const location = useLocation();
    const { darkMode, color } = useSelector((state) => state.global);
    const { restaurantsDetail } = useSelector((state) => state.restaurants);

    const showBackground =
        location.pathname !== `/restaurant/${restaurantsDetail?.restaurantID}`;

    const openRoutes =
        location.pathname !== "/restaurants" &&
        location.pathname !== "/profile";

    function getCurrentYear() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        return currentYear;
    }

    const icons = [
        {
            icon: twiterVector,
            type: "twiter",
        },
        {
            icon: fb,
            type: "facebook",
        },
        {
            icon: insta,
            type: "instagram",
        },
        {
            icon: discord,
            type: "discord",
        },
        {
            icon: website,
            type: "website",
        },
        {
            icon: linkedinVector,
            type: "linkedIn",
        },
        {
            icon: snapchat,
            type: "snapchat",
        },
        {
            icon: tiktok,
            type: "tiktok",
        },
        {
            icon: youtube,
            type: "youtube",
        },
        {
            icon: linktree,
            type: "linktree",
        },
    ];
    return (
        <div
            className={`${
                darkMode
                    ? "footer-dark position-sticky"
                    : "footer-light position-sticky"
            }`}
        >
            <Container className="footer">
                {showBackground ? (
                    <>
                        <Row className="py-5 footer__top-row">
                            {openRoutes && (
                                <>
                                    <Col className="footer__left-col">
                                        <div className="footer__left-col__brand">
                                            <img
                                                src={
                                                    restaurantsDetail?.restaurantLogo ||
                                                    logoIpsum
                                                }
                                                alt="minuex darker"
                                            />
                                        </div>
                                    </Col>
                                    <Col className="footer__right-col">
                                        <Row className="justify-content-center">
                                            {restaurantsDetail?.socialProfiles?.map(
                                                (item, index) => {
                                                    const matchedIcon =
                                                        icons.find(
                                                            (icon) =>
                                                                icon.type ===
                                                                    item.type &&
                                                                item.url
                                                        );

                                                    return (
                                                        <React.Fragment
                                                            key={index}
                                                        >
                                                            {matchedIcon ? (
                                                                <>
                                                                    {" "}
                                                                    <a
                                                                        href={
                                                                            item?.url
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                background: `${color}`,
                                                                            }}
                                                                            className="social_icon d-flex justify-content-center align-items-center"
                                                                        >
                                                                            {matchedIcon && (
                                                                                <img
                                                                                    className="icon"
                                                                                    src={
                                                                                        matchedIcon.icon
                                                                                    }
                                                                                    alt={
                                                                                        item.type
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </a>
                                                                </>
                                                            ) : null}
                                                        </React.Fragment>
                                                    );
                                                }
                                            )}
                                        </Row>
                                    </Col>
                                </>
                            )}
                        </Row>
                        <Row className=" d-flex copyright__row justify-content-center  align-items-start align-items-md-center align-items-lg-center align-items-xl-center flex-nowrap mx-2">
                            <img
                                src={darkMode ? menuxbrandLight : menuxbrand}
                                alt="minuex darker"
                                className="mr-2"
                                height={20}
                                width={20}
                            />
                            <h6 className="footer__bottom-text">
                                Copyright © {getCurrentYear()} • NextEats All
                                rights reserved
                            </h6>
                        </Row>
                        <Row className="justify-content-center  fs--14 pt-5 pb-3">
                            <a
                                className="footer__bottom-typography"
                                href="https://nexteats-main.web.app/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.NextEats.com
                            </a>
                        </Row>
                    </>
                ) : (
                    <Row className="py-5 footer__top-row">
                        <Col className="footer__single-col col-12">
                            <Row className="justify-content-center pt-5 pb-3 mx-4">
                                {restaurantsDetail?.socialProfiles?.map(
                                    (item, index) => {
                                        const matchedIcon = icons.find(
                                            (icon) =>
                                                icon.type === item.type &&
                                                item.url
                                        );

                                        return (
                                            <React.Fragment key={index}>
                                                {matchedIcon ? (
                                                    <>
                                                        {" "}
                                                        <a
                                                            href={item?.url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            key={index}
                                                        >
                                                            <div
                                                                style={{
                                                                    background: `${color}`,
                                                                }}
                                                                className="social_icon d-flex justify-content-center align-items-center"
                                                            >
                                                                {matchedIcon && (
                                                                    <img
                                                                        className="icon"
                                                                        src={
                                                                            matchedIcon.icon
                                                                        }
                                                                        alt={
                                                                            item.type
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                        </a>
                                                    </>
                                                ) : null}
                                            </React.Fragment>
                                        );
                                    }
                                )}
                            </Row>
                        </Col>
                    </Row>
                )}
            </Container>
        </div>
    );
};

export default Footer;
