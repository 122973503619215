import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";
import moment from "moment";

const GeneralInfoSection = ({ tracker }) => {
    const location = useLocation();
    const pathName = location.pathname;
    const { darkMode } = useSelector((state) => state.global);

    const seconds = tracker?.createdAt?.seconds;
    const nano = tracker?.createdAt?.nanoseconds;

    const unixTimestamp = seconds * 1000 + nano / 1000000;
    const date = moment.unix(unixTimestamp / 1000);
    const formattedDateTime = date.format("DD/MM/YYYY, HH:mm");

    return (
        <Row className="d-flex my-4 flex-column align-items-center justify-content-center">
            <Col className="d-flex justify-content-start align-items-center px-3">
                <h5
                    className={
                        darkMode
                            ? " fs--22 fw--600 mb-0 normal-heading-dark "
                            : " fs--22 fw--600 mb-0 normal-heading"
                    }
                >
                    General Info
                </h5>
            </Col>
            <Col
                className={`
            d-flex flex-column py-3 px-3 ${
                pathName == "/tracking" && darkMode
                    ? "bg-transparent mt-3"
                    : darkMode
                    ? "bg-site-black mt-3"
                    : "bg-site-white mt-3"
            }            
            `}
            >
                <div className="d-flex justify-content-between px-3 py-2 cart__shipping">
                    <p
                        className={
                            darkMode
                                ? "fs--18 fw--400 normal-heading-dark"
                                : "fs--18 fw--400 "
                        }
                    >
                        Order ID
                    </p>
                    <p
                        className={
                            darkMode
                                ? "fs--18 fw--600 normal-heading-dark"
                                : "fs--18 fw--600 "
                        }
                    >
                        {tracker?.id}
                    </p>
                </div>
                <div className="d-flex justify-content-between mt-2 px-3">
                    <p
                        className={
                            darkMode
                                ? "fs--18 fw--400 normal-heading-dark"
                                : "fs--18 fw--400 "
                        }
                    >
                        Order Date
                    </p>
                    <p
                        className={
                            darkMode
                                ? "fs--18 fw--600 normal-heading-dark"
                                : "fs--18 fw--600 "
                        }
                    >
                        {formattedDateTime}
                    </p>
                </div>
            </Col>
        </Row>
    );
};

export default GeneralInfoSection;
