/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Container, Spinner } from "reactstrap";
import CategoriesSection from "../components/CategoriesSection";
import Menus from "../components/Menus";
import { getMenuCategories, getMenuItems } from "../store/actions/MenuActions";
import { getCatalogsAction, getRestaurantsAction } from "../store/actions/restaurantsAction";
import HeroSection from "./heroSection";
import CatalogSection from "../components/CatalogSection";

const AdminView = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { darkMode } = useSelector((state) => state.global);

    const { menuCategories, menuItems, categoriesLoading } = useSelector(
        (state) => state.parentMenu
    );
    const {catalogs} =useSelector((state)=>state.restaurants);
    const { restaurantsDetail } = useSelector((state) => state.restaurants);

    const [activeTab, setActiveTab] = useState("");

    useEffect(() => {
        if (restaurantsDetail !== undefined) {
            setLoading(Object.keys(restaurantsDetail).length === 0);
        }
    }, [restaurantsDetail]);

    useEffect(() => {
        setActiveTab(menuCategories[0]?.id);
    }, [menuCategories]);

    useEffect(() => {
        dispatch(getMenuCategories(id));
    }, [id]);

    useEffect(() => {
        if (menuCategories && menuCategories.length > 0) {
            const restaurantID =
                localStorage.getItem("restaurantID") ||
                menuCategories[0]?.restaurantID;
            localStorage.setItem("restaurantID", restaurantID);
            dispatch(getRestaurantsAction(restaurantID));
            dispatch(getCatalogsAction(restaurantID));
        }
    }, [menuCategories, dispatch]);

    useEffect(() => {
        if (restaurantsDetail?.restaurantID && activeTab !== undefined) {
            if (activeTab !== "") {
                dispatch(
                    getMenuItems(restaurantsDetail?.restaurantID, activeTab)
                );
            }
        }
    }, [restaurantsDetail?.restaurantID, activeTab]);

    return (
        <>
            {loading ? (
                <Container className="product_details d-flex justify-content-center align-items-center">
                    <Spinner color="primary" />
                </Container>
            ) : (
                <Container className={`product_details`}>
                    {" "}
                    <HeroSection />
                   {catalogs?.length>0 &&<CatalogSection
                    catalogs={catalogs}
                    />
                   }
                    <CategoriesSection
                        categories={menuCategories}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        categoriesLoading={categoriesLoading}
                    />
                    <Menus  menuItems={menuItems} />
                </Container>
            )}
        </>
    );
};

export default AdminView;
