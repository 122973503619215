import React from "react";
import { Card, Col, Container, Row } from "reactstrap";
import { useSelector } from "react-redux";
import SignUpForm from "../../components/SignUpForm";

const Signup = () => {
    const { darkMode } = useSelector((state) => state.global);

    return (
        <div className="auth">
            <Container className="py-5">
                <Row className="d-flex flex-column justify-content-center align-items-center">
                    <Col className="d-flex justify-content-center">
                        <h3
                            className={
                                darkMode
                                    ? "fs--30 fw--600 normal-heading-dark"
                                    : "fs--30 fw--600 normal-heading"
                            }
                        >
                            Register
                        </h3>
                    </Col>
                    <Col className="d-flex mt-5 justify-content-center align-items-center">
                        <Card
                            className={
                                darkMode ? "auth__card-dark" : "auth__card"
                            }
                        >
                            <SignUpForm />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Signup;
