import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import {
    decreaseQuantity,
    increaseQuantity,
} from "../store/actions/cartAction";
import { FaMinus, FaPlus } from "react-icons/fa";

const ItemCounter = ({
    selectedItemsSize,
    setSelectedItemsSize,
    cartItem,
    type,
}) => {
    const { darkMode, color } = useSelector((state) => state.global);
    const dispatch = useDispatch();

    const [value, setValue] = useState(0);

    const increment = (v) => {
        const newQuantity = selectedItemsSize?.quantity + v;
        const newPrice =
            Number(selectedItemsSize?.totalPrice) +
            Number(selectedItemsSize?.price);
        setValue(newQuantity);
        setSelectedItemsSize((obj) => ({
            ...obj,
            quantity: newQuantity,
            price: Number(selectedItemsSize?.price),
            totalPrice: Number(newPrice),
        }));
    };

    const decrement = (v) => {
        if (selectedItemsSize?.quantity > 0) {
            const newQuantity = selectedItemsSize?.quantity - v;
            const newPrice =
                Number(selectedItemsSize?.totalPrice) -
                Number(selectedItemsSize?.price);
            setValue(newQuantity);
            setSelectedItemsSize((obj) => ({
                ...obj,
                quantity: newQuantity,
                price: Number(selectedItemsSize?.price),
                totalPrice: Number(newPrice),
            }));
        }
    };

    return (
        <Row className="my-3">
            <div
                onClick={() => {
                    type == "cart"
                        ? dispatch(decreaseQuantity(cartItem))
                        : decrement(Number(1));
                }}
                className={
                    darkMode
                        ? "top_cards-dark__operators d-flex justify-content-center align-items-center"
                        : "top_cards__operators  d-flex justify-content-center align-items-center"
                }
            >
                <FaMinus />
            </div>
            <div
                className={
                    darkMode ? "top_cards-dark__number" : "top_cards__number"
                }
            >
                {type == "cart"
                    ? cartItem.quantity
                    : selectedItemsSize?.size == null
                    ? 0
                    : selectedItemsSize?.quantity}
            </div>
            <div
                onClick={() => {
                    type == "cart"
                        ? dispatch(increaseQuantity(cartItem))
                        : increment(Number(1));
                }}
                style={{ border: `2px solid ${color}`, color: `${color}` }}
                className={
                    darkMode
                        ? "top_cards-dark__operators-active  d-flex justify-content-center align-items-center"
                        : "top_cards__operators-active  d-flex justify-content-center align-items-center"
                }
            >
                <FaPlus />
            </div>
        </Row>
    );
};

export default ItemCounter;
