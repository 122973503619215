import { ORDER_LOADER, GET_ORDER_HISTORY, GETPAYMENT_METHOD } from "../types";
import { AES, enc } from "crypto-js";
import firebase from "../../config/firebase";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
export const orderLoader = (val) => async (dispatch) => {
    dispatch({
        type: ORDER_LOADER,
        payload: val,
    });
};

export const addOrderAction =
    (payload, onSuccess, onError) => async (dispatch) => {
        let orderId = uuidv4();
        const { answers, ...orderData } = payload;

        try {
            dispatch(orderLoader(true));
            firebase
                .firestore()
                .collection("orders")
                .doc(orderId)
                .set({
                    ...orderData,
                    createdAt: firebase.firestore.Timestamp.now(),
                    orderId,
                })
                .then((res) => {
                    dispatch(orderLoader(false));
                    onSuccess(orderId);

                    // Create checkoutResponses documents
                    let batch = firebase.firestore().batch();
                    Object.keys(payload.answers).forEach((questionId) => {
                        let docRef = firebase
                            .firestore()
                            .collection("checkoutResponses")
                            .doc();
                        let answer = payload.answers[questionId];
                        batch.set(docRef, {
                            answer: answer.answer,
                            customerId: answer.customerId,
                            orderId: orderId,
                            customerName: answer?.customerName,
                            customerAge: answer?.customerAge,
                            customerGender: answer?.customerGender,
                            questionId: questionId,
                            createdAt: firebase.firestore.Timestamp.now(),
                        });
                    });

                    batch
                        .commit()
                        .then(() => {})
                        .catch((error) => {
                            console.error(
                                "Error writing checkout responses: ",
                                error
                            );
                        });
                })
                .catch((e) => {
                    onError(e.message);
                    toast.error(e.message);
                    dispatch(orderLoader(false));
                });
        } catch (err) {
            dispatch(orderLoader(false));
            toast.error(err.message);
        }
    };

export const getOrderHistoryAction = (uid) => async (dispatch) => {
    try {
        firebase
            .firestore()
            .collection("orders")
            .where("customerID", "==", uid)
            .onSnapshot(async (query) => {
                let temp = [];
                for (let doc of query.docs) {
                    temp.push({ id: doc.id, ...doc.data() });
                }
                dispatch({ type: GET_ORDER_HISTORY, payload: temp });
            });
    } catch (err) {
        toast.error(err.message);
    }
};
export const getPaymentMethodAction = (id) => async (dispatch) => {
    try {
        await firebase
            .firestore()
            .collection("paymentMethods")
            .where("customerID", "==", id)
            .get()
            .then((data) => {
                let temp = [];
                for (let i = 0; i < data?.docs?.length; i++) {
                    let result = decryptCardObject(data?.docs[i]?.data()?.card);
                    temp.push({
                        id: data?.docs[i]?.id,
                        ...result,
                        sample: data?.docs[i]?.data()?.sample,
                    });
                }
                dispatch({
                    type: GETPAYMENT_METHOD,
                    payload: temp,
                });
            });
    } catch (e) {
        toast.error(e?.response?.data?.message);
    }
};

export const updateMethodAction = (obj, onSuccess) => async (dispatch) => {
    dispatch(updatePaymentLoader(true));
    try {
        let sampleCard = maskCardNumber(obj?.card?.number);
        let storeCard = encryptCardObject(obj?.card);
        await firebase
            .firestore()
            .collection("paymentMethods")
            .doc(obj?.cardID)
            .update({
                customerID: obj?.customerID,
                sample: sampleCard,
                card: storeCard,
            })
            .then((data) => {
                onSuccess();
                dispatch(getPaymentMethodAction(obj?.customerID));
                dispatch(updatePaymentLoader(false));
            });
    } catch (e) {
        toast.error(e?.response?.data?.message);
        dispatch(updatePaymentLoader(false));
    }
};

export const visitorIncrement = (id) => async (dispatch) => {
    try {
        firebase
            .firestore()
            .collection("menus")
            .doc(id)
            .update({
                views: firebase.firestore.FieldValue.increment(1),
            });
    } catch (err) {
        toast.error(err.message);
    }
};

export const restaurantVisitorAdd = (obj) => async (dispatch) => {
    try {
        firebase.firestore().collection("visits").add(obj);
    } catch (err) {
        toast.error(err.message);
    }
};
export const deleteCardAction = (id, uid) => async (dispatch) => {
    try {
        await firebase
            .firestore()
            .collection("paymentMethods")
            .doc(id)
            .delete()
            .then((data) => {
                dispatch(getPaymentMethodAction(uid));
                toast.success("Order Deleted Successfully!");
            });
    } catch (e) {
        toast.error(e?.response?.data?.message);
    }
};

export const addCardAction = (obj, id) => async (dispatch) => {
    try {
        let sampleCard = maskCardNumber(obj?.card?.number);
        let storeCard = encryptCardObject(obj?.card);
        await firebase.firestore().collection("paymentMethods").add({
            customerID: id,
            sample: sampleCard,
            card: storeCard,
        });
    } catch (e) {
        toast.error(e?.response?.data?.message);
    }
};

const maskCardNumber = (card) => {
    const visibleDigits = 4;
    const maskedDigits = card?.length - visibleDigits;
    const maskedChars = "*".repeat(maskedDigits);
    return `${maskedChars}${card?.slice(-visibleDigits)}`;
};
const encryptCardObject = (object) => {
    const jsonString = JSON.stringify(object);
    const encryptedData = AES.encrypt(jsonString, "ENCRYPTION_KEY").toString();

    return encryptedData;
};

const decryptCardObject = (encryptedData) => {
    // Decrypt the encrypted data
    const decryptedBytes = AES.decrypt(encryptedData, "ENCRYPTION_KEY");
    const decryptedString = decryptedBytes.toString(enc.Utf8);

    // Parse the decrypted JSON string to an object
    const decryptedObject = JSON.parse(decryptedString);

    return decryptedObject;
};

export const updatePaymentLoader = (payload) => async (dispatch) => {
    dispatch({
        type: "UPDATE_PAYMENT_LOADER",
        payload: payload,
    });
};
