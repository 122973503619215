import Cart from "../views/Cart";
import Login from "../views/auth/Login";
import Payment from "../views/Payment";
import ProductDetails from "../views/ProductDetails";
import ProfileDetails from "../views/auth/ProfileDetails";
import Signup from "../views/auth/Signup";
// import AuthView from "../views/auth/AuthView";
import MainView from "../views/MainView";
import Tracking from "../views/Tracking";
import Table from "../views/Table";
import OrderHistory from "../views/OrderHistory";
import Comparison from "../views/Comparison";
import PaymentMethod from "../views/PaymentMethod";
import LikedItems from "../views/LikedItems";
import ViewAllProduct from "../views/ViewAllProduct";
import FeedBack from "../views/FeedBack";
import RestaurantHome from "../views/RestaurantHome";
import Info from "../views/Info";
import ResturantList from "../views/ResturantList";

let routes = [
    {
        path: "/signin",
        component: Login,
        layout: "auth",
    },
    {
        path: "/register",
        component: Signup,
        layout: "auth",
    },
    {
        path: "/profile",
        component: ProfileDetails,
        layout: "main",
    },
    {
        path: "/restaurants",
        component: ResturantList,
        layout: "main",
    },
    {
        path: "/restaurant/:id",
        component: RestaurantHome,
        layout: "main",
    },
    {
        path: "/restaurant-details/:id",
        component: MainView,
        layout: "main",
    },
    {
        path: "/productDetails/:id",
        component: ProductDetails,
        layout: "main",
    },
    {
        path: "/liked",
        component: LikedItems,
        layout: "main",
    },
    {
        path: "/feedback",
        component: FeedBack,
        layout: "main",
    },
    {
        path: "/info",
        component: Info,
        layout: "main",
    },
    {
        path: "/cart",
        component: Cart,
        layout: "main",
    },
    {
        path: "/payment",
        component: Payment,
        layout: "main",
    },
    {
        path: "/payment-method",
        component: PaymentMethod,
        layout: "main",
    },
    {
        path: "/tracking/orderID=:id",
        component: Tracking,
        layout: "main",
    },
    {
        path: "/viewall-product",
        component: ViewAllProduct,
        layout: "main",
    },
    {
        path: "/table/:id/:number",
        component: Table,
        layout: "main",
    },
    {
        path: "/order-history",
        component: OrderHistory,
        layout: "main",
    },
    {
        path: "/comparison",
        component: Comparison,
        layout: "main",
    },
];
export default routes;
