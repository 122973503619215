import { TextField } from "@mui/material";
import React from "react";
import { Col, Row } from "reactstrap";
import { withStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";

const PayWithCards = () => {
    const { darkMode, color } = useSelector((state) => state.global);

    const theme = createTheme({
        palette: {
            secondary: {
                main: `${color ? color : "#000000"}`,
            },
        },
    });

    const inputProps = {
        className: darkMode
            ? "my-notched-outline-dark"
            : "my-notched-outline-light",
    };

    const labelProps = {
        style: {
            color: darkMode ? "#95989a" : "rgb(101 92 92)",
        },
    };

    const inputColor = darkMode ? "#ffffff" : "rgb(101 92 92)";
    const CustomTextField = withStyles({
        root: {
            "& fieldset": {
                borderColor: darkMode ? "#95989a" : "rgba(0, 0, 0, 0.125)",
            },
        },
    })(TextField);

    return (
        <Row className="justify-content-center">
            <Col
                className={
                    darkMode
                        ? "col-12 pay-with-card-dark"
                        : "col-12 pay-with-card"
                }
            >
                <ThemeProvider theme={theme}>
                    <CustomTextField
                        id="outlined-required"
                        label="Card Number"
                        fullWidth
                        color="secondary"
                        placeholder="42 42 42 42 42"
                        className="my-4"
                        inputProps={inputProps}
                        InputLabelProps={labelProps}
                        InputProps={{
                            style: {
                                color: inputColor,
                            },
                        }}
                        variant="outlined"
                    />
                    <CustomTextField
                        id="outlined-required"
                        label="Expiration date"
                        fullWidth
                        color="secondary"
                        placeholder="01/24"
                        className="mb-4"
                        inputProps={inputProps}
                        InputLabelProps={labelProps}
                        InputProps={{
                            style: {
                                color: inputColor,
                            },
                        }}
                        variant="outlined"
                    />
                    <CustomTextField
                        id="outlined-required"
                        label="Card Number"
                        fullWidth
                        color="secondary"
                        placeholder="22343"
                        className="mb-4"
                        inputProps={inputProps}
                        InputLabelProps={labelProps}
                        InputProps={{
                            style: {
                                color: inputColor,
                            },
                        }}
                        variant="outlined"
                    />
                </ThemeProvider>
                <h6 className="fs--14 fw--400 text-muted">What is this?</h6>
            </Col>
        </Row>
    );
};

export default PayWithCards;
