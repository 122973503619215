import React from "react";
import { Button, Form, Row, Spinner } from "reactstrap";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { withStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { signInAction } from "../store/actions/authAction";
import { useDispatch } from "react-redux";

const LoginForm = () => {
    const { darkMode, color } = useSelector((state) => state.global);
    const history = useHistory();
    const [signInData, setSignInData] = useState({
        email: "",
        password: "",
    });
    const handleChangeData = (e) => {
        setSignInData((obj) => ({
            ...obj,
            [e.target.name]: e.target.value,
        }));
    };

    const theme = createTheme({
        palette: {
            secondary: {
                main: `${color ? color : "#000000"}`,
            },
        },
    });

    const inputProps = {
        className: darkMode
            ? "my-notched-outline-dark"
            : "my-notched-outline-light",
    };

    const labelProps = {
        style: {
            color: darkMode ? "#ffffff" : "rgb(101 92 92)",
        },
    };

    const inputColor = darkMode ? "#ffffff" : "rgb(101 92 92)";

    const CustomTextField = withStyles({
        root: {
            "& fieldset": {
                borderColor: darkMode ? "#ffffff" : "rgba(0, 0, 0, 0.125)",
            },
        },
    })(TextField);
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.user);

    let cartPath = localStorage.getItem("cartPath");
    return (
        <Form
            className={darkMode ? "px-3 pay-with-card" : "px-3 "}
            onSubmit={(e) => {
                e.preventDefault();
                dispatch(
                    signInAction(signInData, () => {
                        cartPath
                            ? history.push("/cart")
                            : history.push(`/restaurants`);
                    })
                );
            }}
        >
            <ThemeProvider theme={theme}>
                <CustomTextField
                    id="outlined-required"
                    label="Email"
                    fullWidth
                    autoComplete="off"
                    className="my-4"
                    color="secondary"
                    type="email"
                    name="email"
                    defaultValue={signInData.email}
                    onBlur={(e) => {
                        handleChangeData(e);
                    }}
                    inputProps={inputProps}
                    InputLabelProps={labelProps}
                    InputProps={{
                        style: {
                            color: inputColor,
                        },
                    }}
                    variant="outlined"
                />
                <CustomTextField
                    id="outlined-required"
                    label="Password"
                    fullWidth
                    className="mb-4"
                    autoComplete="off"
                    type="password"
                    color="secondary"
                    name="password"
                    defaultValue={signInData.password}
                    onBlur={(e) => {
                        handleChangeData(e);
                    }}
                    inputProps={inputProps}
                    InputLabelProps={labelProps}
                    InputProps={{
                        style: {
                            color: inputColor,
                        },
                    }}
                    variant="outlined"
                />
            </ThemeProvider>
            {}
            <Button
                className="py-3 w-100 auth__btn"
                style={{ background: `${color}` }}
                type="submit"
                disabled={loading}
            >
                {loading ? <Spinner size="md" /> : `${"Login"}`}
            </Button>
            {}

            <p className="mt-3 text-center">
                Don’t have an account?{" "}
                <span
                    className="cursor-pointer"
                    style={{ color: `${color}` }}
                    onClick={() => history.push("/register")}
                >
                    Register Now
                </span>
            </p>
        </Form>
    );
};

export default LoginForm;
