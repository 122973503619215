import React, { useState } from "react";
import RestaurantCard from "../components/RestaurantCard";
import { Container, Input, Row } from "reactstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const ResturantList = () => {
    const history=useHistory();
    const {user}=useSelector((state)=>state.authUser);
    const [searchValue, setSearchValue] = useState("");
    if(user?.onetimelogin){
        history.push('/profile');
    }
    return (
        <>
            <Container>
                <Row className="mx-3">
                    <Input
                        type="text"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder="Search Restaurant"
                        className="my-3 mb-4 w-100"
                    />
                </Row>
                <RestaurantCard searchValue={searchValue} />
            </Container>
        </>
    );
};

export default ResturantList;
