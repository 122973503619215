export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const AUTH_SET_LOADING = "AUTH_SET_LOADING";
export const AUTH_NOTIFICATION = "AUTH_NOTIFICATION";
export const REGISTER_LOADER = "REGISTER_LOADER";
export const GET_RESTAURANT = "GET_RESTAURANT";
export const GET_CATALOGS = "GET_CATALOGS";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const TRACK = "TRACK";
export const GET_MENUS = "GET_MENUS";
export const ADD_TO_CART = "ADD_TO_CART";
export const DELETE_CART = "DELETE_CART";
export const INCREASE_ITEM_QUANTITY = "INCREASE_ITEM_QUANTITY";
export const DECREASE_ITEM_QUANTITY = "DECREASE_ITEM_QUANTITY";
export const ORDER_LOADER = "ORDER_LOADER";
export const CLEAR_CART = "CLEAR_CART";
export const SINGLE_USER = "SINGLE_USER";
export const GET_ORDER_HISTORY = "GET_ORDER_HISTORY";
export const GET_CHECKOUT_QUESTIONS = "GET_CHECKOUT_QUESTIONS";
export const GETPAYMENT_METHOD = "GETPAYMENT_METHOD";
export const ADD_FEED_LOADER = "ADD_FEED_LOADER";
export const LOGINAGAIN = "LOGINAGAIN";
