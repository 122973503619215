import { ORDER_LOADER, GET_ORDER_HISTORY, GETPAYMENT_METHOD } from "../types";

const initialState = {
    loader: false,
    orderHistory: [],
    paymentCard: [],
    updatePaymentLoader: false,
};

export default function orderReducer(state = initialState, { type, payload }) {
    switch (type) {
        case ORDER_LOADER:
            return {
                ...state,
                loader: payload,
            };
        case GET_ORDER_HISTORY:
            return {
                ...state,
                orderHistory: payload,
            };
        case GETPAYMENT_METHOD:
            return {
                ...state,
                paymentCard: payload,
            };
        case "UPDATE_PAYMENT_LOADER":
            return {
                ...state,
                updatePaymentLoader: payload,
            };
        default:
            return { ...state };
    }
}
