import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

const Shipment = ({ data, color }) => {
    const { darkMode } = useSelector((state) => state.global);

    return (
        <Row className="d-flex flex-column  ">
            <Col>
                <h4
                    className={
                        darkMode
                            ? " fs--18 fw--600 mb-0  normal-heading-dark"
                            : " fs--18 fw--600 mb-0  normal-heading"
                    }
                >
                    Shipping details
                </h4>
            </Col>
            <Col>
                <div
                    className={`py-3 mt-3 d-flex align-items-start cart__shipping ${
                        darkMode ? "bg-site-black" : "bg-site-white"
                    }`}
                >
                    {}
                    <div>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_373_41083)">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M19.3808 3.23717L21.8808 7.40384C21.9588 7.53343 22 7.68179 22 7.83301C22.0018 8.47718 21.8165 9.108 21.4666 9.64889C21.1167 10.1898 20.6174 10.6174 20.0291 10.88C19.4409 11.1425 18.7891 11.2286 18.1528 11.1278C17.5166 11.027 16.9233 10.7437 16.445 10.3122C15.8357 10.8638 15.0432 11.1693 14.2213 11.1693C13.3994 11.1693 12.6068 10.8638 11.9975 10.3122C11.3885 10.8626 10.5968 11.1674 9.77584 11.1674C8.95491 11.1674 8.16321 10.8626 7.55417 10.3122C6.94336 10.8639 6.14896 11.1684 5.32584 11.1663H5.31917C4.4371 11.1641 3.59199 10.8118 2.9696 10.1867C2.3472 9.56168 1.99846 8.71508 2.00001 7.83301C2.00005 7.68179 2.04125 7.53343 2.11917 7.40384L4.61917 3.23717C4.69323 3.11392 4.79791 3.01192 4.92306 2.94109C5.0482 2.87027 5.18954 2.83304 5.33334 2.83301H18.6667C18.8105 2.83304 18.9518 2.87027 19.077 2.94109C19.2021 3.01192 19.3068 3.11392 19.3808 3.23717ZM16.4475 12.3097C17.1412 12.6544 17.9054 12.8336 18.68 12.833C18.955 12.8294 19.2293 12.8032 19.5 12.7547V21.1663C19.5 21.3874 19.4122 21.5993 19.2559 21.7556C19.0996 21.9119 18.8877 21.9997 18.6667 21.9997H13.6667V16.9997H10.3333V21.9997H5.33333C5.11232 21.9997 4.90036 21.9119 4.74408 21.7556C4.5878 21.5993 4.5 21.3874 4.5 21.1663V12.758C4.7728 12.8053 5.04898 12.8304 5.32583 12.833C6.09892 12.8343 6.86174 12.656 7.55417 12.3122C7.75887 12.4135 7.97018 12.501 8.18667 12.5738C8.80604 12.7844 9.46101 12.8701 10.1137 12.8259C10.7664 12.7817 11.4038 12.6085 11.9892 12.3163L11.9983 12.3122C12.2033 12.4135 12.4149 12.501 12.6317 12.5738C13.2513 12.7839 13.9064 12.8693 14.5592 12.8249C15.2119 12.7806 15.8495 12.6075 16.435 12.3155L16.4475 12.3097Z"
                                    fill={color}
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_373_41083">
                                    <rect
                                        width="20"
                                        height="20"
                                        fill="white"
                                        transform="translate(2 2)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className=" mx-3">
                        <h6
                            className={
                                darkMode
                                    ? " fs--18 fw--500 my-1  normal-heading-dark"
                                    : " fs--18 fw--500 my-1"
                            }
                        >
                            {data.shipmentFrom}
                        </h6>
                        <h5
                            className={
                                darkMode
                                    ? "text-capitalize fs--18 fw--600 mb-0  normal-heading-dark"
                                    : "text-capitalize fs--18 fw--600 mb-0"
                            }
                        >
                            {data.storeName}
                        </h5>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default Shipment;
