import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
// Import CSS here
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/custom.scss";
import store, { persistor } from "./store";
import { Provider } from "react-redux";
import { LoadScript } from "@react-google-maps/api";
import { PersistGate } from "redux-persist/integration/react"; // Import PersistGate
Sentry.init({
    dsn: "https://34d9b60ca4b33bd74a8189955d2af164@o4506981619269632.ingest.us.sentry.io/4507145904979968",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ToastContainer className="toast-font" />
                <LoadScript
                    loading="async"
                    loadingElement={
                        <div
                            className="d-flex align-items-center justify-content-center w-100 "
                            style={{ height: "100vh" }}
                        >
                            <div
                                className="spinner-grow text-danger"
                                role="status"
                            ></div>
                        </div>
                    }
                    libraries={["places"]}
                    googleMapsApiKey={"AIzaSyCqDBbD8FWoozg43IbPISgnDpwNhvNmqh0"}
                    onLoad={() => {
                        console.log("Google Maps API loaded");
                    }}
                >
                    <App />
                </LoadScript>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
