import {
    ADD_TO_CART,
    DELETE_CART,
    INCREASE_ITEM_QUANTITY,
    DECREASE_ITEM_QUANTITY,
    CLEAR_CART,
    GET_CHECKOUT_QUESTIONS,
} from "../types";
import firebase from "../../config/firebase";
export const addToCartAction =
    (data, onSuccess = () => {}) =>
    async (dispatch) => {
        await dispatch({ type: ADD_TO_CART, payload: data });
        onSuccess();
    };
export const deleteCartAction = (payload) => async (dispatch) => {
    await dispatch({ type: DELETE_CART, payload });
};
export const increaseQuantity = (payload) => async (dispatch) => {
    await dispatch({ type: INCREASE_ITEM_QUANTITY, payload: payload });
};

export const decreaseQuantity = (payload) => async (dispatch) => {
    await dispatch({ type: DECREASE_ITEM_QUANTITY, payload: payload });
};
export const clearCartAction = () => async (dispatch) => {
    await dispatch({ type: CLEAR_CART });
};

export const updateTip = (payload) => async (dispatch) => {
    await dispatch({ type: "TIP_DETAILS", payload: payload });
};
export const updateTable = (payload) => async (dispatch) => {
    await dispatch({ type: "TABLE_UPDATE", payload: payload });
};
export const updateDelivery = (payload) => async (dispatch) => {
    await dispatch({ type: "DELIVERY_UPDATE", payload: payload });
};

export const getCheckoutQuestions = (restaurantID) => async (dispatch) => {
    const questionsRef = await firebase
        .firestore()
        .collection("checkoutQuestions")
        .where("restaurantID", "==", restaurantID)
        .orderBy("position", "asc");

    questionsRef.onSnapshot((snap) => {
        let questions = [];

        snap.forEach((doc) => {
            questions.push({ id: doc.id, ...doc.data() });
        });

        dispatch({
            type: GET_CHECKOUT_QUESTIONS,
            payload: questions,
        });
    });
};
