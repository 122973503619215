import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
    Button,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Row,
    Spinner,
} from "reactstrap";
import MainNavigation from "../components/MainNavigation";
import { addRequest } from "../store/actions/restaurantsAction";

const Table = () => {
    const dispatch = useDispatch();
    const { id, number } = useParams();
    const location = useLocation();
    const { darkMode, color } = useSelector((state) => state.global);
    const { requestLoading } = useSelector((state) => state.restaurants);
    const restaurantID = location?.state?.restaurantID;
    const customerID = location?.state?.customerID;
    const [tableNumber, setTableNumber] = useState("");
    const [comments, setComments] = useState("");
    const [message, setMessage] = useState("");
    const [radioComments, setRadioComments] = useState("request some food");

    useEffect(() => {
        setTableNumber(number);
    }, [number, id]);

    const radioOptions = [
        "request some food",
        "i need water",
        "I need serviette papers",
    ];

    useEffect(() => {
        if (message) {
            setComments(message);
        } else {
            setComments(radioComments);
        }
    }, [message, radioComments]);

    return (
        <>
            <div className="table">
                <div
                    className={`${
                        darkMode ? "table__overlay-dark" : "table__overlay"
                    }`}
                >
                    <div className="table__frame d-flex justify-content-center">
                        <svg
                            width="349"
                            height="244"
                            viewBox="0 0 349 244"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M337.863 163.624C337.863 253.991 369.086 237.136 174.239 237.136C-16.2609 237.136 10.6151 253.991 10.6151 163.624C10.6151 73.2569 83.872 0 174.239 0C264.606 0 337.863 73.2569 337.863 163.624Z"
                                fill="#EF2B55"
                                fillOpacity="0.1"
                            />
                            <ellipse
                                cx="174.295"
                                cy="234.119"
                                rx="174.295"
                                ry="9.88067"
                                fill="#BABABA"
                                fillOpacity="0.4"
                            />
                            <rect
                                x="50.6879"
                                y="117.659"
                                width="21.5728"
                                height="120.808"
                                fill={color}
                            />
                            <rect
                                x="275.043"
                                y="117.659"
                                width="21.5728"
                                height="120.808"
                                fill={color}
                            />
                            <path
                                d="M33.4291 104.715H313.875V104.715C313.875 116.63 304.217 126.288 292.303 126.288H55.0019C43.0875 126.288 33.4291 116.63 33.4291 104.715V104.715Z"
                                fill={color}
                            />
                            <rect
                                x="11.8575"
                                y="78.828"
                                width="323.592"
                                height="30.2019"
                                rx="15.101"
                                fill={color}
                            />
                            <rect
                                x="72.2616"
                                y="126.288"
                                width="8.62912"
                                height="103.549"
                                fill={color}
                            />
                            <rect
                                x="266.416"
                                y="126.288"
                                width="8.62912"
                                height="103.549"
                                fill={color}
                            />
                            <path
                                d="M132.663 39.3682C132.663 34.9499 136.245 31.3682 140.663 31.3682H206.64C211.058 31.3682 214.64 34.9499 214.64 39.3682V78.8283H132.663V39.3682Z"
                                fill="white"
                            />
                        </svg>
                    </div>

                    <Container className="table__navigation">
                        <MainNavigation title={"Request Service"} />
                    </Container>
                </div>
            </div>
            <Container className="table__bottom">
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        let obj = {
                            id: id !== "request" ? id : "N/A",
                            tableNumber,
                            comments,
                            restaurantID,
                            customerID,
                        };
                        dispatch(
                            addRequest(obj, () => {
                                setComments("");
                                setTableNumber("");
                                setRadioComments("request some food");
                                setMessage("");
                            })
                        );
                    }}
                >
                    <FormGroup className="mb-0">
                        <Label
                            className={` mb-0 ml-2 fs--16 fw--600 ${
                                darkMode
                                    ? "normal-heading-dark"
                                    : "normal-heading"
                            }`}
                        >
                            Table Number
                        </Label>
                        <InputGroup
                            className={
                                darkMode
                                    ? "table__search-box-dark my-2"
                                    : "table__search-box my-2"
                            }
                        >
                            <div>
                                {/* <img alt="" src={inputIcon} /> */}
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <mask
                                        id="mask0_811_22218"
                                        maskUnits="userSpaceOnUse"
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                    >
                                        <g clipPath="url(#clip0_811_22218)">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M19.3808 3.23749L21.8808 7.40416C21.9588 7.53376 22 7.68211 22 7.83333C22.0018 8.4775 21.8165 9.10832 21.4666 9.64921C21.1167 10.1901 20.6174 10.6178 20.0291 10.8803C19.4409 11.1428 18.7891 11.2289 18.1528 11.1281C17.5166 11.0273 16.9233 10.744 16.445 10.3125C15.8357 10.8641 15.0432 11.1696 14.2213 11.1696C13.3994 11.1696 12.6068 10.8641 11.9975 10.3125C11.3885 10.8629 10.5968 11.1677 9.77584 11.1677C8.95491 11.1677 8.16321 10.8629 7.55417 10.3125C6.94336 10.8643 6.14896 11.1688 5.32584 11.1667H5.31917C4.4371 11.1645 3.59199 10.8121 2.9696 10.1871C2.3472 9.562 1.99846 8.7154 2.00001 7.83333C2.00005 7.68211 2.04125 7.53376 2.11917 7.40416L4.61917 3.23749C4.69323 3.11424 4.79791 3.01224 4.92306 2.94141C5.0482 2.87059 5.18954 2.83336 5.33334 2.83333H18.6667C18.8105 2.83336 18.9518 2.87059 19.077 2.94141C19.2021 3.01224 19.3068 3.11424 19.3808 3.23749ZM16.4475 12.31C17.1412 12.6548 17.9054 12.8339 18.68 12.8333C18.955 12.8297 19.2293 12.8035 19.5 12.755V21.1667C19.5 21.3877 19.4122 21.5996 19.2559 21.7559C19.0996 21.9122 18.8877 22 18.6667 22H13.6667V17H10.3333V22H5.33333C5.11232 22 4.90036 21.9122 4.74408 21.7559C4.5878 21.5996 4.5 21.3877 4.5 21.1667V12.7583C4.7728 12.8056 5.04898 12.8307 5.32583 12.8333C6.09892 12.8346 6.86174 12.6563 7.55417 12.3125C7.75887 12.4139 7.97018 12.5013 8.18667 12.5742C8.80604 12.7847 9.46101 12.8704 10.1137 12.8262C10.7664 12.782 11.4038 12.6088 11.9892 12.3167L11.9983 12.3125C12.2033 12.4139 12.4149 12.5013 12.6317 12.5742C13.2513 12.7843 13.9064 12.8696 14.5592 12.8252C15.2119 12.7809 15.8495 12.6078 16.435 12.3158L16.4475 12.31Z"
                                                fill="#1A94FF"
                                            />
                                        </g>
                                    </mask>
                                    <g mask="url(#mask0_811_22218)">
                                        <rect
                                            width="24"
                                            height="24"
                                            fill={color}
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_811_22218">
                                            <rect
                                                width="20"
                                                height="20"
                                                fill="white"
                                                transform="translate(2 2)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <Input
                            required
                                className="fs--16 fw--600"
                                placeholder="Table Number"
                                type="number"
                                min={1}
                                // value={number < 9 ? `0${number}` : number}
                                value={tableNumber}
                                onChange={(e) => setTableNumber(e.target.value)}
                            />
                        </InputGroup>
                    </FormGroup>

                    <Label
                        className={` mb-2 ml-2 fs--16 fw--600 ${
                            darkMode ? "normal-heading-dark" : "normal-heading"
                        }`}
                    >
                        Reason
                    </Label>
                    {radioOptions?.map((option,index) => {
                        const isChecked = radioComments === option;
                        const borderColor = isChecked ? color : "#dbdbdb";
                        return (
                            <FormGroup
                            key={index}
                                check
                                className="table__check-box"
                                // style={{ border: `1px solid ${color}` }}
                                style={{
                                    border: `1px solid ${
                                        darkMode ? "#7a7e83" : borderColor
                                    }`,
                                }}
                            >
                                <Input
                                required
                                    disabled={message !== ""}
                                    name="radio1"
                                    type="radio"
                                    checked={isChecked}
                                    onChange={() => setRadioComments(option)}
                                    style={{
                                        "--accent-color": `${color}`,
                                    }}
                                />{" "}
                                <Label
                                    check
                                    className={`text-capitalize fs--15 fw--600 ${
                                        darkMode
                                            ? "normal-heading-dark"
                                            : "normal-heading"
                                    }`}
                                >
                                    {option}
                                </Label>
                            </FormGroup>
                        );
                    })}

                    <InputGroup
                        className={
                            darkMode
                                ? "table__search-box-dark mb-3 mt-2"
                                : "table__search-box mb-3 mt-2"
                        }
                    >
                        <Input
                            className="fs--16 fw--600 text-capitalize"
                            placeholder="type any other..."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </InputGroup>
                    <Row className="d-flex flex-column align-items-center justify-content-center p-3">
                        <Button
                            type="sumbit"
                            className="table__call_btn fs--16 fw--400 my-4"
                            // onClick={() => history.push("/payment")}
                            disabled={!tableNumber || !comments}
                            style={{ background: `${color}` }}
                        >
                            {requestLoading ? (
                                <Spinner size="sm" />
                            ) : (
                                "Call Waiter"
                            )}
                        </Button>
                        <p
                            className="fs--16 table__help_support cursor-pointer"
                            style={{ color: `${color}` }}
                        >
                            Support & Help
                        </p>
                    </Row>
                </Form>
            </Container>
        </>
    );
};

export default Table;
