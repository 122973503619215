import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Row, Spinner } from "reactstrap";
import logoD from "../assets/img/logoDark.png";
import logo from "../assets/img/logoWhite.png";
import { signUpAction } from "../store/actions/authAction";

const SignUpForm = () => {
    const outerTheme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const { darkMode, color } = useSelector((state) => state.global);
    const { loading } = useSelector((state) => state.user);
    const inputColor = darkMode ? "#ffffff" : "rgb(101 92 92)";
    const [age, setAge] = useState();
    const [signUpData, setSignUpData] = useState({
        name: "",
        contactNumber: "",
        email: "",
        password: "",
        confirmPassword: "",
        age: "",
        DOB: "",
        gender: "",
        darkMode: false,
        mainColor: "#d70365",
    });

    const labelProps = {
        style: {
            color: darkMode ? "#ffffff" : "rgb(101 92 92)",
        },
    };

    const customTheme = (outerTheme) =>
        createTheme({
            palette: {
                mode: outerTheme.palette.mode,
            },
            components: {
                MuiOutlinedInput: {
                    styleOverrides: {
                        notchedOutline: {
                            borderColor: inputColor,
                        },
                        root: {
                            [`&:hover .${outlinedInputClasses.notchedOutline}`]:
                                {
                                    borderColor: "#000000",
                                },
                            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]:
                                {
                                    borderColor: `${color || "#000000"}`,
                                },
                        },
                    },
                },
            },
        });

    const handleChangeData = (e) => {
        if (e.target.name == "DOB") {
            setAge(e.target.value);
            handleAge(e.target.value);
            setSignUpData((obj) => ({
                ...obj,
                [e.target.name]: e.target.value,
            }));
        } else
            setSignUpData((obj) => ({
                ...obj,
                [e.target.name]: e.target.value,
            }));
    };

    function calculateAge(dateOfBirth) {
        var dateParts = dateOfBirth.split("-");
        var day = parseInt(dateParts[2]);
        var month = parseInt(dateParts[1]) - 1;
        var year = parseInt(dateParts[0]);

        var birthday = new Date(year, month, day);
        if (isNaN(birthday.getTime())) {
            return null;
        }

        var today = new Date();
        var age = today.getFullYear() - birthday.getFullYear();
        var monthDifference = today.getMonth() - birthday.getMonth();
        if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthday.getDate())
        ) {
            age--;
        }
        return age;
    }

    const handleAge = (e) => {
        const ageValue = calculateAge(e);
        if (ageValue) {
            setSignUpData((obj) => ({
                ...obj,
                ["age"]: Number(ageValue),
            }));
        }
    };

    return (
        <>
            <ThemeProvider theme={customTheme(outerTheme)}>
                <Form
                    className="px-3"
                    onSubmit={(e) => {
                        e.preventDefault();

                        if (signUpData.password == signUpData.confirmPassword) {
                            dispatch(
                                signUpAction(signUpData, () => {
                                    history.push("/signin");
                                })
                            );
                        } else {
                            toast.error("Password did not match");
                        }
                    }}
                >
                    <Row className="pb-4 justify-content-center position-relative">
                        {}
                        <img
                            alt="image_upload"
                            src={darkMode === true ? logo : logoD}
                            className="image_upload__arrow"
                            width="60px"
                        />
                    </Row>
                    <TextField
                        required
                        id="outlined-required"
                        label="Full Name"
                        fullWidth
                        className="mt-4 mb-3"
                        type="text"
                        name="name"
                        defaultValue={signUpData.name}
                        onBlur={(e) => {
                            handleChangeData(e);
                        }}
                        color="secondary"
                        InputLabelProps={labelProps}
                        InputProps={{
                            style: {
                                color: inputColor,
                            },
                        }}
                        variant="outlined"
                    />
                    <TextField
                        required
                        id="outlined-required"
                        label="Phone No."
                        fullWidth
                        className="mb-3"
                        type="number"
                        name="contactNumber"
                        defaultValue={signUpData.contactNumber}
                        onBlur={(e) => {
                            handleChangeData(e);
                        }}
                        color="secondary"
                        InputLabelProps={labelProps}
                        InputProps={{
                            style: {
                                color: inputColor,
                            },
                        }}
                        variant="outlined"
                    />
                    <TextField
                        required
                        id="outlined-required"
                        fullWidth
                        className="mb-3"
                        type="date"
                        name="DOB"
                        placeholder="mm/dd/yyyy"
                        defaultValue={signUpData.DOB}
                        onBlur={(e) => {
                            handleChangeData(e);
                        }}
                        color="secondary"
                        inputProps={{
                            max: new Date(new Date().getFullYear() - 2, 4, 31) // Set December 31st of the previous year
                            .toISOString()
                            .split("T")[0],
                        }}
                        InputLabelProps={labelProps}
                        InputProps={{
                            style: {
                                color: inputColor,
                            },
                        }}
                        variant="outlined"
                    />
                    <TextField
                        required
                        id="outlined-required"
                        label="Email"
                        fullWidth
                        className="mb-3"
                        type="email"
                        color="secondary"
                        name="email"
                        defaultValue={signUpData.email}
                        onBlur={(e) => {
                            handleChangeData(e);
                        }}
                        InputLabelProps={labelProps}
                        InputProps={{
                            style: {
                                color: inputColor,
                            },
                        }}
                        variant="outlined"
                    />
                    <TextField
                        required
                        id="outlined-required"
                        label="Password"
                        fullWidth
                        className="mb-3"
                        type="password"
                        color="secondary"
                        name="password"
                        defaultValue={signUpData.password}
                        onBlur={(e) => {
                            handleChangeData(e);
                        }}
                        InputLabelProps={labelProps}
                        InputProps={{
                            style: {
                                color: inputColor,
                            },
                        }}
                        variant="outlined"
                    />
                    <TextField
                        required
                        id="outlined-required"
                        label="Confirm Password"
                        fullWidth
                        className="mb-4"
                        name="confirmPassword"
                        defaultValue={signUpData.confirmPassword}
                        onBlur={(e) => {
                            handleChangeData(e);
                        }}
                        type="password"
                        color="secondary"
                        InputLabelProps={labelProps}
                        InputProps={{
                            style: {
                                color: inputColor,
                            },
                        }}
                        variant="outlined"
                    />
                    <FormControl required fullWidth>
                        <InputLabel
                            style={{
                                color: darkMode ? "#ffffff" : "rgb(101 92 92)",
                            }}
                            id="demo-simple-select-required-label"
                        >
                            Gender
                        </InputLabel>
                        <Select
                            sx={{
                                borderColor: inputColor,
                            }}
                            required
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            className="mb-4"
                            label="Gender *"
                            name="gender"
                            value={signUpData.gender}
                            onChange={(e) => handleChangeData(e)}
                            color="secondary"
                            style={{
                                color: inputColor,
                            }}
                            InputProps={{
                                style: {
                                    color: inputColor,
                                },
                            }}
                            InputLabelProps={labelProps}
                            variant="outlined"
                        >
                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="female">Female</MenuItem>
                        </Select>
                    </FormControl>

                    {}
                    <Button
                        className="py-3 w-100 auth__btn pointer"
                        style={{ background: `${color}` }}
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? <Spinner size="md" /> : `${"Sign Up"}`}
                    </Button>
                    {}
                    <p
                        className="mt-3 text-center"
                        style={{ color: inputColor }}
                    >
                        Don’t have an account?{" "}
                        <span
                            className="cursor-pointer"
                            style={{ color: `${color}` }}
                            onClick={() => history.push("/signin")}
                        >
                            Login Now
                        </span>
                    </p>
                </Form>
            </ThemeProvider>
        </>
    );
};

export default SignUpForm;
