import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import MainNavigation from "../components/MainNavigation";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteCardAction,
    getPaymentMethodAction,
} from "../store/actions/orderAction";
import UpdateStripe from "../components/Payments/UpdateStripe";
import { useState } from "react";

import { FaEdit, FaTrashAlt } from "react-icons/fa";

const PaymentMethod = () => {
    let { uid } = useSelector((state) => state.authUser);
    const { darkMode, color } = useSelector((state) => state.global);
    const dispatch = useDispatch();
    const [openStripe, setOpenStripe] = useState(false);
    const toggle = () => {
        setOpenStripe(!openStripe);
    };
    const { paymentCard } = useSelector((state) => state.orders);
    useEffect(() => {
        dispatch(getPaymentMethodAction(uid));
    }, [uid]);

    const [cardID, setCardID] = useState();

    const deleteCard = (id) => {
        dispatch(deleteCardAction(id, uid));
    };
    return (
        <Container className="payment_method py-3">
            <MainNavigation title={"Payment Method"} />
            {openStripe == true && (
                <>
                    <UpdateStripe
                        setOpenStripe={setOpenStripe}
                        cardID={cardID}
                    />
                </>
            )}

            {paymentCard
                ?.filter(
                    (card, index, self) =>
                        index ===
                        self.findIndex(
                            (c) =>
                                c.number === card.number && c.cvc === card.cvc
                        )
                )
                .map((card) => {
                    return (
                        <Card
                            className={`py-4 px-3 my-2 ${
                                darkMode
                                    ? "payment_method__card-dark"
                                    : "payment_method__card"
                            }`}
                            key={card.id}
                        >
                            <Row
                                className={`m-0 d-flex justify-content-between align-items-center ${
                                    darkMode
                                        ? "payment_method__card-dark_row"
                                        : "payment_method__card_row"
                                }`}
                            >
                                <Col md="3">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <h5 className=" typograhy mx-3">
                                            {card?.brand}
                                        </h5>
                                    </div>
                                </Col>
                                <Col md="5">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <h5 className=" typograhy">
                                                {card?.sample}
                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <h5
                                            className="mb-1 mx-2 card_status text-capitalize"
                                            style={{ color: `${color}` }}
                                        >
                                            connected
                                            {/* {card.status} */}
                                        </h5>
                                        <FaTrashAlt
                                            onClick={() => {
                                                deleteCard(card.id);
                                            }}
                                            className="text-danger cursor-pointer mr-3 fs--24"
                                        />
                                        <FaEdit
                                            onClick={() => {
                                                setOpenStripe(!openStripe);
                                                setCardID(card?.id);
                                            }}
                                            className="text-success cursor-pointer fs--24"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    );
                })}
        </Container>
    );
};

export default PaymentMethod;
