import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Col, FormGroup, Input, Row } from "reactstrap";
import ItemCounter from "./ItemCounter";
import cash from "../assets/img/cash 1.svg";
import card from "../assets/img/PaymentCard.png";
import { deleteCartAction } from "../store/actions/cartAction";
import pizzalabel from "../assets/img/pizzalabel.svg";
import { FaTrashAlt } from "react-icons/fa";

const OrderedItems = ({ data, title }) => {
    const location = useLocation();
    const pathName = location.pathname;
    const { darkMode, color } = useSelector((state) => state.global);
    const dispatch = useDispatch();

    return (
        <>
            {title !== "Payment Info" ? (
                <>
                    <Row className="d-flex flex-column  mt-3">
                        <Col>
                            <h4
                                className={
                                    darkMode
                                        ? "text-capitalize fs--18 fw--600 mb-0  normal-heading-dark"
                                        : "text-capitalize fs--18 fw--600 mb-0  normal-heading"
                                }
                            >
                                {title}
                            </h4>
                        </Col>
                        <Col
                            className={
                                pathName == "/tracking" && darkMode
                                    ? "bg-transparent mt-3"
                                    : darkMode
                                    ? "bg-site-transparent mt-3"
                                    : "bg-site-transparent mt-3"
                            }
                        >
                            {data?.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className=" py-3 d-flex align-items-start cart__shipping"
                                    >
                                        <img
                                            alt="item-media"
                                            src={
                                                item?.images?.url
                                                    ? item?.images?.url
                                                    : pizzalabel
                                            }
                                            width={50}
                                            height={50}
                                        />
                                        <div className="mx-3 w-100">
                                            <h4
                                                className={
                                                    darkMode
                                                        ? "text-capitalize fs--18 fw--600 normal-heading-dark"
                                                        : "text-capitalize fs--18 fw--600 "
                                                }
                                            >
                                                {item?.name}
                                            </h4>
                                            <h6 className="fs--16 text-muted fw--400 text-capitalize">
                                                Size : {item?.size}
                                            </h6>
                                            <h4
                                                className={
                                                    darkMode
                                                        ? "fs--18 fw--600 normal-heading-dark"
                                                        : "fs--18 fw--600"
                                                }
                                            >
                                                {item?.currency}{" "}
                                                {item?.totalPrice}
                                            </h4>
                                            {item?.content && (
                                                <FormGroup
                                                    className={
                                                        darkMode
                                                            ? "top_cards-dark__textarea"
                                                            : "top_cards__textarea"
                                                    }
                                                >
                                                    <Input
                                                        type="textarea"
                                                        name="text"
                                                        id="exampleText"
                                                        placeholder={
                                                            item.content
                                                        }
                                                        rows={4}
                                                        cols={40}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            )}
                                            {title !== "Payment Info" &&
                                                title !== "Product Info" && (
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <ItemCounter
                                                            cartItem={item}
                                                            type="cart"
                                                        />
                                                        <FaTrashAlt
                                                            onClick={() => {
                                                                dispatch(
                                                                    deleteCartAction(
                                                                        item
                                                                    )
                                                                );
                                                            }}
                                                            className="text-danger"
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                );
                            })}
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    {}
                    <Col
                        className={
                            pathName == "/tracking" && darkMode
                                ? "bg-transparent mt-3"
                                : darkMode
                                ? "bg-site-black mt-3"
                                : "bg-site-white mt-3"
                        }
                    >
                        <div className=" py-3 d-flex align-items-start cart__shipping">
                            {data === "Paypal" || data === "Stripe" ? (
                                <img
                                    alt="Payment"
                                    src={card}
                                    className="PaymentCard"
                                />
                            ) : (
                                <img
                                    alt="Payment"
                                    className="PaymentCard"
                                    src={cash}
                                />
                            )}
                            <div className="mx-3 w-100">
                                <h4 className="fs--18 text-muted fw--500 text-capitalize">
                                    Payment Method
                                </h4>

                                {}
                                <h4
                                    className={
                                        darkMode
                                            ? "fs--18 fw--600 normal-heading-dark"
                                            : "fs--18 fw--600"
                                    }
                                >
                                    {data ? data : ""}
                                </h4>

                                {data?.content && (
                                    <FormGroup
                                        className={
                                            darkMode
                                                ? "top_cards-dark__textarea"
                                                : "top_cards__textarea"
                                        }
                                    >
                                        <Input
                                            type="textarea"
                                            name="text"
                                            id="exampleText"
                                            placeholder={data.content}
                                            defaultValue={data.content}
                                            rows={4}
                                            cols={40}
                                            disabled
                                        />
                                    </FormGroup>
                                )}
                                {title !== "Payment Info" &&
                                    title !== "Product Info" && (
                                        <div className="d-flex justify-content-end align-items-center mr-5">
                                            {}
                                            <i className="fas fa-trash-alt fa-2x text-danger pointer"></i>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </Col>
                    {}
                </>
            )}
        </>
    );
};

export default OrderedItems;
