import React from "react";
import { useSelector } from "react-redux";
import { Container, Row, Spinner,Col } from "reactstrap";
import meat from "../assets/img/meat.svg";

const CatalogSection = (props) => {
    const { darkMode, color } = useSelector((state) => state.global);
    const { catalogs } = props;
    const formatDate = (date) => {
        return new Date(date.seconds * 1000 + date.nanoseconds / 1e6).toLocaleDateString('en-GB', {
            day: '2-digit', month: 'short', year: 'numeric'
        });
    }
    const convertToAmPm=(timeString)=>{
        // Assuming the input is in HH:mm format
        const [hours, minutes] = timeString.split(':');
        
        // Create a date object. Using a fixed date since only the time part is used.
        const date = new Date();
        date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0);
        
        // Format the date object to a time string with AM/PM
        const amPmTimeString = date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        });
        
        return amPmTimeString;
    }
    return (
        <Container className="categories">
            <div>
                <h4
                    className={
                        darkMode
                            ? "fs--18 fw--600 normal-heading-dark"
                            : "fs--18 fw--600 normal-heading"
                    }
                >
                    Catalogs
                </h4>
            </div>
            <Row className=" my-5">
                        {catalogs?.map((item) => {
                           const { startDate, endDate } = item.availabilityDate || {};
                    
                           const formattedStartDate = startDate ? formatDate(startDate) : '';
                           const formattedEndDate = endDate ? formatDate(endDate) : '';
       
                           const dateRange = `${formattedStartDate} - ${formattedEndDate}`;  
                            return (
                                <Col lg="3" md='3'  style={{ background: `${color}` }} className= "mt-2 d-flex justify-content-center px-5 pt-4 pb-4 rounded offset-md-1   ">
                                <div>
                                    <h6  className="text-white"
                           >{item?.category?.toUpperCase()}</h6>
                                  {!item?.availabilityDate &&<span className="text-white"
                           >{item?.availability}</span>}
                                     {item?.availabilityDate &&  
                                    
                                        <span className="text-white"> 
                                    {dateRange}
                                </span>
                                }
                                <br/>
                                  <span className="text-white"
                                 >{convertToAmPm(item?.startTime)}-{convertToAmPm(item?.endTime)}</span>
                                </div>
                              </Col>
                            );
                        })}
                </Row>
        </Container>
    );
};

export default CatalogSection;
