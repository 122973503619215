import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import lock from "../assets/img/lock.svg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import MainNavigation from "../components/MainNavigation";
import SavedCards from "../components/SavedCards";
import AddNewCard from "../components/AddNewCard";
import OrderCompleted from "../components/OrderCompleted";
import {
    addOrderAction,
    getPaymentMethodAction,
} from "../store/actions/orderAction";
import { clearCartAction } from "../store/actions/cartAction";
import Paypal from "../components/Payments/PaypalPayment";
import StripePayment from "../components/Payments/Stripe";
import { getRestaurantsAction } from "../store/actions/restaurantsAction";

const Payment = () => {
    const history = useHistory();
    const { darkMode, color } = useSelector((state) => state.global);
    const [newCard, setNewCard] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("");
    const [payment, setPayment] = useState();
    const [renderCards, setRenderCards] = useState(false);

    const { loader } = useSelector((state) => state.orders);
    let { uid } = useSelector((state) => state.authUser);
    const { singleUser } = useSelector((state) => state.user);
    const { restaurantsDetail } = useSelector((state) => state.restaurants);
    let paypalFees = restaurantsDetail?.forwardFees?.find(
        (val) => val?.provider == "paypal"
    );
    let stripeFees = restaurantsDetail?.forwardFees?.find(
        (val) => val?.provider == "stripe"
    );

    let restaurantID = localStorage.getItem("restaurantID");
    useEffect(() => {
        if (restaurantID) {
            dispatch(getRestaurantsAction(restaurantID));
        }
    }, [restaurantID]);

    useEffect(() => {
        dispatch(getPaymentMethodAction(uid));
    }, [uid]);
    const location = useLocation();
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const dispatch = useDispatch();

    const addOrderData = (e) => {
        e.preventDefault();

        let obj = {
            ...location.state,
            customerID: uid,
            customerName: singleUser?.name,
            paymentMethod: paymentMethod,
            status: "pending",
            paymentRecieved: false,
        };

        if (obj.paymentMethod == undefined) {
            toast.info("select the payment method");
            return;
        } else {
            dispatch(
                addOrderAction(obj, (orderID) => {
                    history.push(`/tracking/orderID=${orderID}`);
                    dispatch(clearCartAction());
                    localStorage.removeItem("cartPath");
                })
            );
        }
    };
    const getPaypalDetail = (details, finalAmount) => {
        let obj = {
            ...location.state,
            totalAmount: finalAmount || 0,
            customerID: uid,
            customerName: singleUser?.name,
            paymentMethod: "Paypal",
            status: "pending",
            paymentID: details?.id,
        };
        dispatch(
            addOrderAction(obj, (orderID) => {
                history.push(`/tracking/orderID=${orderID}`);
                dispatch(clearCartAction());
                localStorage.removeItem("cartPath");
            })
        );
    };
    return (
        <div className="cart">
            <Container>
                <MainNavigation title={"Payment"} />
                <Row className="d-flex flex-column pt-3">
                    <Col>
                        <h4
                            className={
                                darkMode
                                    ? " fs--18 fw--600 mb-0  normal-heading-dark"
                                    : " fs--18 fw--600 mb-0  normal-heading"
                            }
                        >
                            Payment Method
                        </h4>
                    </Col>
                    <Col className="my-4 pb-3 d-flex flex-column align-items-md-center justify-content-start ">
                        <Card
                            className={
                                darkMode
                                    ? "p-3 mb-3 cart__payment_card-dark"
                                    : "p-3 mb-3 cart__payment_card"
                            }
                        >
                            <FormGroup check>
                                <Label check className="fs--18 fw--600 ">
                                    <Input
                                        type="radio"
                                        name="pay"
                                        value={paymentMethod}
                                        onChange={() => {
                                            setPaymentMethod("cash");
                                            setRenderCards(false);
                                        }}
                                        style={{ "--accent-color": `${color}` }}
                                    />{" "}
                                    Pay With Cash
                                </Label>
                            </FormGroup>
                            {/* {paymentMethod == "cash" && <StripePayment />} */}
                        </Card>
                        {restaurantsDetail?.paymentConnections?.stripe ||
                        restaurantsDetail?.paymentConnections?.paypal ? (
                            <Card
                                className={
                                    darkMode
                                        ? "px-3 mb-3 cart__payment_card-payment-dark"
                                        : "px-3 mb-3 cart__payment_card-payment"
                                }
                            >
                                <FormGroup check>
                                    <Label check className="fs--18 fw--600 ">
                                        <Input
                                            type="radio"
                                            name="pay"
                                            value={paymentMethod}
                                            onChange={() => {
                                                restaurantsDetail
                                                    ?.paymentConnections?.stripe
                                                    ? setPaymentMethod("Stripe")
                                                    : setPaymentMethod(
                                                          "Paypal"
                                                      );
                                                setRenderCards(true);
                                            }}
                                            style={{
                                                "--accent-color": `${color}`,
                                            }}
                                        />{" "}
                                        Pay With Card
                                    </Label>
                                </FormGroup>
                            </Card>
                        ) : (
                            ""
                        )}
                        {renderCards && (
                            <>
                                {restaurantsDetail ? (
                                    <>
                                        {restaurantsDetail?.paymentConnections
                                            ?.paypal &&
                                            restaurantsDetail?.paypalClientKey &&
                                            restaurantsDetail?.paypalSecretKey && (
                                                <Card
                                                    className={
                                                        darkMode
                                                            ? "p-3 mb-3 cart__payment_card-dark"
                                                            : "p-3 mb-3 cart__payment_card"
                                                    }
                                                >
                                                    <Paypal
                                                        amount={
                                                            location?.state
                                                                ?.totalAmount
                                                        }
                                                        orders={location?.state}
                                                        getPaypalDetail={
                                                            getPaypalDetail
                                                        }
                                                        setPayment={setPayment}
                                                        forwardFees={paypalFees}
                                                    />
                                                </Card>
                                            )}
                                        {restaurantsDetail?.paymentConnections
                                            ?.paypal &&
                                            restaurantsDetail?.paypalClientKey &&
                                            restaurantsDetail?.paypalSecretKey && (
                                                <h4 className="text-secondary">
                                                    OR
                                                </h4>
                                            )}
                                        {restaurantsDetail?.paymentConnections
                                            ?.stripe &&
                                            restaurantsDetail?.stripeClientKey &&
                                            restaurantsDetail?.stripeSecretKey && (
                                                <>
                                                    {" "}
                                                    <StripePayment
                                                        amount={
                                                            location?.state
                                                                ?.totalAmount
                                                        }
                                                        orderData={
                                                            location.state
                                                        }
                                                        forwardFees={stripeFees}
                                                    />
                                                    <SavedCards
                                                        amount={
                                                            location?.state
                                                                ?.totalAmount
                                                        }
                                                        orderData={
                                                            location.state
                                                        }
                                                        forwardFees={stripeFees}
                                                    />
                                                </>
                                            )}
                                    </>
                                ) : (
                                    ""
                                )}
                            </>
                        )}

                        {newCard && <AddNewCard />}
                        <Row>
                            <div className="d-flex justify-content-center align-items-start mx-2">
                                <img
                                    alt="lock"
                                    src={lock}
                                    className={
                                        darkMode ? "lock-bg mx-3" : " mx-3"
                                    }
                                />
                                <h6 className="fs--16 fw--400 mb-0 text-muted">
                                    We protect your payment information using
                                    encryption to provide bank-level security.
                                </h6>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center p-3">
                    <Button
                        style={{ background: `${color}` }}
                        className="top_cards__addtocart fs--16 fw--400 my-4"
                        // onClick={() => {
                        //     toggle();
                        // }}
                        onClick={addOrderData}
                        disabled={
                            loader ||
                            !singleUser?.age ||
                            !singleUser?.DOB ||
                            paymentMethod !== "cash"
                        }
                    >
                        {loader ? <Spinner size="md" /> : `Confirm`}
                    </Button>
                </Row>
                <OrderCompleted
                    toggle={toggle}
                    modal={modal}
                    history={history}
                />
            </Container>
        </div>
    );
};

export default Payment;
