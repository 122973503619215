import { applyMiddleware, compose, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Choose your storage engine
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import CryptoJS from "crypto-js";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["cart"],
};

const saveToLocalStorage = (state) => {
    const serializedUid = CryptoJS.AES.encrypt(
        JSON.stringify(state.authUser),
        "my-secret-key"
    ).toString();

    localStorage.setItem("auth", serializedUid);
};

const checkLocalStorage = () => {
    const serializedUid = localStorage.getItem("auth");
    if (serializedUid === null) return undefined;
    return {
        authUser: JSON.parse(
            CryptoJS.AES.decrypt(serializedUid, "my-secret-key").toString(
                CryptoJS.enc.Utf8
            )
        ),
    };
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(
    persistedReducer,
    checkLocalStorage(),
    composeEnhancers(applyMiddleware(thunk))
);
export const persistor = persistStore(store);
store.subscribe(() => saveToLocalStorage(store.getState()));
export default store;
