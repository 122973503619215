import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import {
    Label,
    Button,
    Spinner,
    Form,
    FormGroup,
    Input,
    Card,
} from "reactstrap";
import {
    addOrderAction,
    getPaymentMethodAction,
    addCardAction,
} from "../../store/actions/orderAction.js";
import { clearCartAction } from "../../store/actions/cartAction.js";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleUser } from "../../store/actions/authAction.js";
import { loadStripe } from "@stripe/stripe-js";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    ElementsConsumer,
  } from "@stripe/react-stripe-js";
let stripePromise =  loadStripe('pk_test_51OzbxVIOXhXw0d3VSU2a8jY5GgIlrjwrUVKHMqnAbqp0pYSv4pXHuaalFy4Aj0V05HjX7j9ag9keg6UE0qrnzd8r00pk5SLPuD');
function StripePayment({ amount, orderData, forwardFees }) {
    const dispatch = useDispatch();
    const { darkMode } = useSelector((state) => state.global);
    let restaurantID = localStorage.getItem("restaurantID");
    let { user, uid } = useSelector((state) => state.authUser);
    const [loader, setLoader] = useState(false);
    function calculateFinalAmount(amount, forwardFees) {
        if (forwardFees?.type == "fixed_price") {
            return amount + parseFloat(forwardFees?.fixedAmount);
        } else if (forwardFees?.type == "percentage") {
            const percentage = parseFloat(forwardFees?.percentageAmount) / 100;
            return amount + amount * percentage;
        } else if (forwardFees?.type == "both") {
            let percentage = parseFloat(forwardFees?.percentageAmount) / 100;
            return (
                amount +
                parseFloat(forwardFees?.fixedAmount) +
                amount * percentage
            );
        }
    }
    const finalAmount = useMemo(
        () => calculateFinalAmount(amount, forwardFees),
        [amount, forwardFees]
    );

    let taxAmount = "";

    if (forwardFees?.type === "fixed_price") {
        taxAmount = forwardFees.fixedAmount;
    } else if (forwardFees?.type === "percentage") {
        taxAmount = parseFloat(forwardFees.percentageAmount);
    } else if (forwardFees?.type === "both") {
        taxAmount = `${parseFloat(forwardFees.percentageAmount)}% + ${
            forwardFees.fixedAmount
        }`;
    }

    const { singleUser } = useSelector((state) => state.user);
    useEffect(() => {
        dispatch(getSingleUser(uid));
        dispatch(getPaymentMethodAction(uid));
    }, [uid]);
    const history = useHistory();
    const [paymentLoader, setPaymentLoader] = useState(false);

    const handleSubmit = async (e,
        elements,
        stripe) => {
        e.preventDefault();
        setPaymentLoader(true);
      
        if (!stripe || !elements) {
            setPaymentLoader(false);
            return;
        }
        const cardElement = elements.getElement(CardNumberElement);

        const { token, error } = await stripe.createToken(
            cardElement
          );
          if (error) {
            console.error("Error:", error);
            return;
        }
        let body = {
           tokenId:token.id,
            restaurantID: restaurantID,
            amount: Number(finalAmount),
            email: user?.email,
            currency: "USD",
        };
        await axios
            .post(
                "https://us-central1-menux1.cloudfunctions.net/app/payment",
                body
            )
            .then(async (res) => {
                let { data } = res;
                dispatch(addCardAction(body, uid));
                await setPaymentLoader(false);
                let obj = {
                    ...orderData,
                    customerID: uid,
                    totalAmount: finalAmount || 0,
                    paymentMethod: "Stripe",
                    status: "pending",
                    customerName: singleUser?.name,
                    paymentID: data?.charge?.id,
                };
                dispatch(
                    addOrderAction(
                        obj,
                        (orderID) => {
                            toast.success("Order Placed Successfully..!");
                            history.push(`/tracking/orderID=${orderID}`);
                            dispatch(clearCartAction());
                            localStorage.removeItem("cartPath");
                            setPaymentLoader(false);
                        },

                        (res) => {
                            setPaymentLoader(false);
                        }
                    )
                );
            })
            .catch((err) => {
                toast.error(err?.message);
                let { data } = err.response;
                console.error(data?.message, "Error");
                setPaymentLoader(false);
                 elements.getElement(CardNumberElement).clear();
    elements.getElement(CardExpiryElement).clear();
    elements.getElement(CardCvcElement).clear();
            });
    };
    return (
        <Card
            className={
                darkMode
                    ? "px-3 mb-3 cart__payment_card-payment-dark "
                    : "px-3 mb-3 cart__payment_card-payment "
            }
        >
                       <Elements stripe={stripePromise}>
                          <ElementsConsumer>
                            {({ elements, stripe }) => (
            <Form onSubmit={(e)=>handleSubmit(
                e,
                elements,
                stripe
                            )}>
                <FormGroup>
                    <Label for="cardNumber">Card Number:</Label>
                     <div className="form-control mt-2 d-flex justify-content-between align-items-center shadow-sm">
                                                    <div
                                                      style={{
                                                        flexBasis: "96%",
                                                      }}
                                                    >
                    <CardNumberElement
                                            required
                                            options={{
                                              placeholder:
                                                "XXXX XXXX XXXX XXXX",
                                              style: {
                                                base: {
                                                  // backgroundColor: "#232733",
                                                  fontSize: "16px",
                                                },
                                                invalid: {
                                                  color: "#9e2146",
                                                },
                                              },
                                            }}
                    />
                    </div>
                    </div>

                </FormGroup>
                <FormGroup>
                    <Label for="expiryDate">Expiry Date:</Label>
                    <div className="form-control mt-2 d-flex justify-content-between align-items-center shadow-sm">
                                                    <div
                                                      style={{
                                                        flexBasis: "96%",
                                                      }}
                                                    >
                     <CardExpiryElement
                                            required
                                            options={{
                                              placeholder: "MM/YY",
                                              style: {
                                                base: {
                                                  fontSize: "16px",
                                                },
                                                invalid: {
                                                  color: "#9e2146",
                                                },
                                              },
                                            }}
                    />
                    </div>
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label for="cvv">CVC/CVV:</Label>
                    <div className="form-control mt-2 d-flex justify-content-between align-items-center shadow-sm">
                                                    <div
                                                      style={{
                                                        flexBasis: "96%",
                                                      }}
                                                    >
                     <CardCvcElement
                                            required
                                            options={{
                                              placeholder: "...",
                                              style: {
                                                base: {
                                                  fontSize: "16px",
                                                },
                                                invalid: {
                                                  color: "#9e2146",
                                                },
                                              },
                                            }}
                    />
                    </div>
                    </div>
                </FormGroup>
                <div className="d-flex justify-content-center my-2">
                    <span className="fs--22 fw--400 mr-1">
                        Tax Fees: {}
                        {taxAmount}
                    </span>

                    <span className="fs--22 fw--400">
                        {forwardFees?.type == "percentage"
                            ? "%"
                            : orderData?.order[0]?.currency}
                    </span>
                </div>
                <div className="d-flex justify-content-center my-1">
                    <span className="fs--22 fw--400 mr-1">
                        Total Amount:{" "}
                        {finalAmount ? finalAmount?.toFixed(2) : 0}
                    </span>
                    <span className="fs--22 fw--400">
                        {orderData?.order[0]?.currency}
                    </span>
                </div>
                <Button
                    className="auth-button mt-2 py-3 btn btn-lg btn-block border border-none"
                    type="submit"
                    style={{
                        backgroundColor: "#f2ba36",
                    }}
                    disabled={paymentLoader}
                >
                    {paymentLoader ? (
                        <Spinner size="sm" />
                    ) : (
                        <h4 className="mb-0">Pay with Stripe</h4>
                    )}
                </Button>
            </Form>
             )}
             </ElementsConsumer>
           </Elements>
        </Card>
    );
}

export default StripePayment;
