import React, { useEffect, useRef, useState } from "react";
import { Navbar, NavbarToggler } from "reactstrap";
import cartDark from "../assets/img/cartDark.png";
import cart from "../assets/img/cart.png";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SideBar from "./SideBar";
import InitialsDDL from "./InitialsDDL";
import MPink from "../../src/assets/img/MPink.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Header = () => {
    const history = useHistory();
    const location = useLocation();
    const { darkMode } = useSelector((state) => state.global);
    const { user } = useSelector((state) => state.authUser);
    const { restaurantsDetail } = useSelector((state) => state.restaurants);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const sidebarRef = useRef(null);
    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useState(false);
    const handleLanguage = () => setIsActive(!isActive);

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setSidebarOpen(false);
        }
    };
    function toggleSidebar() {
        setSidebarOpen(!sidebarOpen);
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const logo =
        location.pathname === `/restaurants`
            ? MPink
            : restaurantsDetail?.restaurantLogo || MPink;

    useEffect(() => {
        const handleLanguage = (e) => {
            if (
                dropdownRef.current !== null &&
                !dropdownRef.current.contains(e.target)
            ) {
                setIsActive(!isActive);
            }
        };

        if (isActive) {
            window.addEventListener("click", handleLanguage);
        }

        return () => {
            window.removeEventListener("click", handleLanguage);
        };
    }, [isActive, dropdownRef]);

    return (
        <div className={darkMode === true ? "Header-dark" : "Header"}>
            {}
            {darkMode === true ? (
                <Navbar color="transparent" dark>
                    <NavbarToggler onClick={toggleSidebar} className="me-2" />
                    <div>
                        <img src={logo} className="img-fluid" alt="logo" />{" "}
                    </div>
                    <div className="me-auto d-flex cursor-pointer justify-content-center align-items-center mx-1 navbar__items">
                        {}

                        {}
                        <InitialsDDL />
                        {}
                        {}
                    </div>
                </Navbar>
            ) : (
                <Navbar color="transparent" light>
                    <NavbarToggler onClick={toggleSidebar} className="me-2" />
                    <div>
                        <img src={logo} className="img-fluid" alt="logo" />{" "}
                    </div>
                    <div className="me-auto d-flex cursor-pointer justify-content-center align-items-center mx-1 navbar__items">
                        {}

                        {}
                        <InitialsDDL />
                        {}
                        {}
                    </div>
                </Navbar>
            )}
            {}
            {}
            <SideBar
                toggleSidebar={toggleSidebar}
                sidebarRef={sidebarRef}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                isActive={isActive}
                setIsActive={setIsActive}
            />
            {}
            {}
        </div>
    );
};

export default Header;
