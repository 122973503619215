import DOMPurify from "dompurify";
import React from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Container, Row } from "reactstrap";

const RecommendedItems = ({ data }) => {
    const history = useHistory();
    const { darkMode, color } = useSelector((state) => state.global);

    return (
        <Container className="most_popular">
            <Row className=" mt-3 d-flex justify-content-between align-items-center">
                <h4
                    className={
                        darkMode
                            ? "fs--18 fw--600 mb-0  normal-heading-dark"
                            : "fs--18 fw--600 mb-0  normal-heading"
                    }
                >
                    Recommended Products
                </h4>
                {}
            </Row>
            <Row className=" py-3 justify-content-center recommended_items align-items-center cursor-pointer">
                {data?.map((item) => {
                    const sanitizedHtml = DOMPurify.sanitize(
                        item?.data?.comments
                    );
                    return (
                        <Col md={8} sm={12} lg={8} xl={8} xs={12}>
                            <div
                                className="d-flex flex-row recommended_card p-2"
                                style={{
                                    background: darkMode
                                        ? "#3d3d3d"
                                        : "#ffffff",
                                }}
                                onClick={() =>
                                    history.push(`/productDetails/${item?.id}`)
                                }
                            >
                                <img
                                    className="mr-3 recommended_card__img"
                                    src={item?.data?.images?.[0]}
                                    alt="product"
                                />
                                <div className="d-flex flex-column recommended_card__body justify-content-around">
                                    <h5
                                        className={
                                            darkMode ? "title-dark" : "title"
                                        }
                                    >
                                        {item?.data?.name}
                                    </h5>

                                    {item?.data?.comments && (
                                        <div
                                            className={
                                                darkMode
                                                    ? "sub_title-dark "
                                                    : "sub_title"
                                            }
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizedHtml,
                                            }}
                                        />
                                    )}
                                    {}
                                    <p
                                        className="mb-0 price"
                                        style={{ color: `${color}` }}
                                    >
                                        ${item?.data?.sizes?.[0]?.price}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    );
                })}

                {}
            </Row>
        </Container>
    );
};

export default RecommendedItems;
