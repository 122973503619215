import {
    GET_RESTAURANT,
    GET_CATALOGS,
    GET_CATEGORIES,
    GET_MENUS,
    TRACK,
    ADD_FEED_LOADER,
} from "../types";

const initialState = {
    restaurantsDetail: {},
    catalogs: [],
    categories: [],
    orderTracker: "",
    requestLoading: false,
    menus: [],
    feedLoader: false,
    LikedMenu: [],
    allRestaurants: [],
};

export default function restaurantsReducer(
    state = initialState,
    { type, payload }
) {
    switch (type) {
        case "ALL_RESTAURANTS":
            return {
                ...state,
                allRestaurants: payload,
            };
        case GET_RESTAURANT:
            return {
                ...state,
                restaurantsDetail: payload,
            };
        case GET_CATALOGS:
            return {
                ...state,
                catalogs: payload,
            };
        case ADD_FEED_LOADER: {
            return {
                ...state,
                feedLoader: payload,
            };
        }
        case GET_CATEGORIES:
            return {
                ...state,
                categories: payload,
            };
        case TRACK:
            return {
                ...state,
                orderTracker: payload,
            };
        case "REQUESTLOADING":
            return {
                ...state,
                requestLoading: payload,
            };

        case GET_MENUS:
            return {
                ...state,
                menus: payload,
            };
        case "LIKED_ITEMS":
            return {
                ...state,
                LikedMenu: payload,
            };
        default:
            return { ...state };
    }
}
