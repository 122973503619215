import React from "react";
import { useSelector } from "react-redux";
import { Card, Row } from "reactstrap";
import PayWithCards from "../components/PayWithCards";
import Visa from "../assets/img/Visa.svg";
import Discover from "../assets/img/Discover.svg";
import Maestro from "../assets/img/Maestro.svg";
import Mastercard from "../assets/img/Mastercard.svg";

const AddNewCard = () => {
    const { darkMode } = useSelector((state) => state.global);

    const cards = [
        {
            src: Visa,
            id: 1,
        },
        {
            src: Discover,
            id: 2,
        },
        {
            src: Maestro,
            id: 3,
        },
        {
            src: Mastercard,
            id: 4,
        },
    ];
    return (
        <Card
            className={
                darkMode
                    ? "p-3 mb-4 cart__payment_card-dark"
                    : "p-3 mb-4 cart__payment_card"
            }
        >
            <Row className="my-3">
                {cards.map((card) => {
                    return (
                        <img
                            alt="card"
                            src={card.src}
                            key={card.id}
                            className="ml-4"
                        />
                    );
                })}
            </Row>

            <PayWithCards />
        </Card>
    );
};

export default AddNewCard;
