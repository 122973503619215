import React from "react";
import Menus from "../components/Menus";
import CardItem from "../components/CardItem";
import { useLocation } from "react-router-dom";
import MainNavigation from "../components/MainNavigation";
import { Container } from "reactstrap";
const ViewAllProduct = () => {
    const location = useLocation();
    return (
        <>
            <div className="cart">
                <Container>
                    <MainNavigation title={"View All Product"} />
                    {/* <div id="google_translate_element"></div> */}

                    <CardItem
                        data={location.state}
                        title={"Menus"}
                        type="all"
                    />
                </Container>
            </div>
        </>
    );
};

export default ViewAllProduct;
