// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import authReducer from "./authReducer";
import globalReducer from "./GlobalReducer";
import restaurantsReducer from "./restaurantsReducer";
import cartReducer from "./cartReducer";
import orderReducer from "./orderReducer";
import MenuReducers from "./MenuReducer";
export let rootReducer = combineReducers({
    authUser: authUserReducer,
    user: authReducer,
    global: globalReducer,
    restaurants: restaurantsReducer,
    cart: cartReducer,
    orders: orderReducer,
    parentMenu: MenuReducers,
});

export default rootReducer;
