import React from "react";
import { useSelector } from "react-redux";
import { Container, Row, Spinner } from "reactstrap";
import meat from "../assets/img/meat.svg";

const CategoriesSection = (props) => {
    const { darkMode, color } = useSelector((state) => state.global);
    const { activeTab, setActiveTab, categories, categoriesLoading } = props;
    return (
        <Container className="categories">
            <div>
                <h4
                    className={
                        darkMode
                            ? "fs--18 fw--600 normal-heading-dark"
                            : "fs--18 fw--600 normal-heading"
                    }
                >
                    Categories
                </h4>
            </div>
            {categoriesLoading ? (
                <Row className="py-5 justify-content-center position-relative">
                    <Spinner color="primary" />
                </Row>
            ) : (
                <Row className="d-flex flex-column categories__menu">
                    <div className="mx-4 my-2 justify-content-center d-flex">
                        {categories?.map((item) => {
                            return (
                                <div
                                    className={`menu_section mx-2 cursor-pointer`}
                                    style={{
                                        borderBottom:
                                            item?.id == activeTab
                                                ? `2px solid ${color}`
                                                : "",
                                    }}
                                    key={item.id}
                                    onClick={() => {
                                        setActiveTab(item?.id);
                                    }}
                                >
                                    <img
                                        alt="Category"
                                        src={
                                            item.imageURL ? item.imageURL : meat
                                        }
                                        width={75}
                                        height={75}
                                        className="rounded"
                                    />
                                    <h5
                                        className={
                                            darkMode
                                                ? "mt-2 mb-0 food_text-dark text-capitalize"
                                                : `mt-2 mb-0 food_text text-capitalize ${
                                                      item?.id === activeTab
                                                          ? "food_text-active"
                                                          : ""
                                                  }`
                                        }
                                        style={{ color: color }}
                                    >
                                        {item?.categoryName}
                                    </h5>
                                </div>
                            );
                        })}
                    </div>
                </Row>
            )}
        </Container>
    );
};

export default CategoriesSection;
