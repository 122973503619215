import React, { useEffect } from "react";
import { Button, Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import MainNavigation from "../components/MainNavigation";
import close from "../assets/img/close.svg";
import pizza from "../assets/img/pizza.png";
import star from "../assets/img/Star 3.png";
import plus from "../assets/img/plus.svg";
import plusWhite from "../assets/img/plusWhite.svg";
import { useSelector } from "react-redux";

const Comparison = () => {
    const { darkMode, color } = useSelector((state) => state.global);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const Dishes = [
        {
            id: "1",
            name: "Peperoni Pizza",
            src: pizza,
            rating: "5.0",
            desc: "  It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
            keyPoints: ["Healthy", "Pineapple Topping"],
            price: "99.99",
        },
        {
            id: "2",
            name: "Peperoni Pizza",
            src: pizza,
            rating: "5.0",
            desc: "  It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
            keyPoints: ["Healthy", "Pineapple Topping"],
            price: "99.99",
        },
        {
            id: "3",
            name: "Peperoni Pizza",
            src: pizza,
            rating: "5.0",
            desc: "  It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
            keyPoints: ["Healthy", "Pineapple Topping"],
            price: "99.99",
        },
        {
            id: "3",
            name: "Peperoni Pizza",
            src: pizza,
            rating: "5.0",
            desc: "  It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
            keyPoints: ["Healthy", "Pineapple Topping"],
            price: "99.99",
        },
    ];
    return (
        <Container className="comparison">
            <MainNavigation title={"Comparison"} />
            <Row className="flex-nowrap comparison__Row justify-content-start">
                {Dishes.map((dish) => {
                    return (
                        <Col
                            className={` mx-2 ${
                                Dishes.length <= 2 ? "col-5" : "col-4"
                            }`}
                            key={dish.id}
                        >
                            <Row className="d-flex justify-content-between align-items-center">
                                <p
                                    className={`mb-0 fs--20 fw--600 ${
                                        darkMode
                                            ? "normal-heading-dark"
                                            : "normal-heading"
                                    }`}
                                >
                                    Dish 1
                                </p>
                                <img src={close} alt="close" />
                            </Row>
                            <Row>
                                <Input
                                    name="name"
                                    placeholder="Pizza"
                                    className={`w-100 mt-2 ${
                                        darkMode
                                            ? "comparison__Row__input-field-dark"
                                            : "comparison__Row__input-field"
                                    }`}
                                />
                            </Row>
                            <Row className="mt-2">
                                <Card className="p-0 w-100 comparison__card">
                                    <img
                                        alt="Sample"
                                        src={dish.src}
                                        className="comparison__card__img"
                                    />
                                    {/* <img
                                        src={plus}
                                        alt=""
                                        className="plus_icon"
                                    /> */}
                                    <svg
                                        width="28"
                                        height="28"
                                        viewBox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="plus_icon"
                                    >
                                        <rect
                                            x="0.5"
                                            y="0.5"
                                            width="26.2554"
                                            height="26.2554"
                                            rx="13.1277"
                                            fill={color}
                                        />
                                        <path
                                            d="M12.6727 19.2554V8H14.5827V19.2554H12.6727ZM8 14.5827V12.6727H19.2554V14.5827H8Z"
                                            fill="white"
                                        />
                                        <rect
                                            x="0.5"
                                            y="0.5"
                                            width="26.2554"
                                            height="26.2554"
                                            rx="13.1277"
                                            stroke="white"
                                        />
                                    </svg>
                                    <CardBody
                                        className={`mx-2 p-3 ${
                                            darkMode
                                                ? "comparison__card_body-dark"
                                                : "comparison__card_body"
                                        }`}
                                    >
                                        <Row className="d-flex justify-content-between align-items-center">
                                            <h5 className="mb-0 fs--16 fw--600 title">
                                                {dish.name}
                                            </h5>
                                            <p className="mb-0 fs--13 typography">
                                                {dish.rating}
                                                <span>
                                                    <img src={star} alt="star" />
                                                </span>
                                            </p>
                                        </Row>
                                        <Row>
                                            <p className="fs--14 mt-2 typography">
                                                {dish.desc}
                                            </p>
                                        </Row>
                                        <Row className="d-flex flex-column justify-content-start align-items-start">
                                            <h5 className="mb-2 fs--14 fw--600 sub-title">
                                                Key Points
                                            </h5>
                                            <div className="d-flex flex-column justify-content-start align-items-start">
                                                {dish.keyPoints.map(
                                                    (point, index) => {
                                                        return (
                                                            <p
                                                                className="mb-2 fs--14 typography"
                                                                key={index}
                                                            >
                                                                {point}
                                                            </p>
                                                        );
                                                    }
                                                )}
                                                <p className="mb-2 fs--14 typography">
                                                    Price ${dish.price}
                                                </p>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Row>
                        </Col>
                    );
                })}
            </Row>
            <Row className="d-flex flex-column align-items-center justify-content-center p-3">
                <Button
                    className="table__call_btn fs--16 fw--400 my-4 d-flex align-items-center justify-content-center "
                    style={{ background: `${color}` }}
                >
                    <span className="mx-2">
                        <img src={plusWhite} alt="plusWhite" />
                    </span>{" "}
                    Compare
                </Button>
            </Row>
        </Container>
    );
};

export default Comparison;
