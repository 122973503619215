import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaUser } from "react-icons/fa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logoutAction } from "../store/actions/authAction";

const InitialsDDL = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showClass, setShowClass] = useState(false);
    const { uid, user } = useSelector((state) => state.authUser);

    const name = user?.name;
    const initials = name
        ? name
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase())
              .join("")
        : "";

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (showClass && !event.target.closest(".dropdown")) {
                setShowClass(false);
            }
        };
        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [showClass]);

    return (
        <div className="dropdown">
            <button
                className="dropbtn"
                onClick={() => setShowClass(!showClass)}
            >
                {uid ? initials : <FaUser className="fs--22" />}
            </button>
            <div className={`dropdown-content ${showClass ? "show" : ""}`}>
                {uid ? (
                    <>
                        <li onClick={() => history.push("/profile")}>
                            Profile
                        </li>
                        <li onClick={() => history.push("/cart")}>Cart</li>
                        <li onClick={() => dispatch(logoutAction())}>Logout</li>
                    </>
                ) : (
                    <>
                        <li onClick={() => history.push("/signin")}>Login</li>
                    </>
                )}
            </div>
        </div>
    );
};

export default InitialsDDL;
