import React, { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { getProduct } from "../store/actions/GlobalAction";
import ItemCounter from "./ItemCounter";
import pizzalabel from "../assets/img/pizzalabel.svg";

const ItemsDetail = ({
    id,
    setLoader,
    data,
    title,
    cart,
    setSelectedItemsSize,
    existCartData,
    currency,
}) => {
    const dispatch=useDispatch();
    const { darkMode, color, singleProduct } = useSelector(
        (state) => state.global
    );
    useEffect(()=>{
        dispatch(
            getProduct(id, () => {
                setLoader(false);
            })
        );
    },[id])
    const [selectedName, setSelectedName] = useState(null);

    useEffect(() => {
        if (existCartData !== undefined) {
            setSelectedName(existCartData?.size);
            setSelectedItemsSize({
                size: existCartData?.size,
                price: existCartData?.price,
                quantity: existCartData?.quantity,
                totalPrice: existCartData?.totalPrice,
            });
        }else if(singleProduct?.sizes?.length>0) {
            setSelectedName(singleProduct?.sizes[0]?.subItem);
            const item=singleProduct?.sizes[0];
            const price=item?.price;
            const size=item?.subItem;
            setSelectedItemsSize({
                price: Number(price),
                size,
                quantity:0,
                totalPrice:0,
            })
        }
    }, [existCartData]);

    const handleChange = (size, price) => {
        if (existCartData?.size == size && existCartData?.price == price) {
            setSelectedName(existCartData?.size);
            setSelectedItemsSize({
                size: existCartData?.size,
                price: existCartData?.price,
                quantity: existCartData?.quantity,
                totalPrice: existCartData?.totalPrice,
            });
        } else {
            setSelectedName(size);
            setSelectedItemsSize({
                size: size,
                price: Number(price),
                quantity: 0,
                totalPrice: 0,
            });
        }
    };
    return (
        <>
            <Row className="mx-2 my-3 d-flex flex-column justify-content-start align-items-start">
                <h4
                    className={
                        darkMode
                            ? "fs--18 fw--600 mb-0  normal-heading-dark"
                            : "fs--18 fw--600 mb-0  normal-heading"
                    }
                >
                    {title.title? title.title:""} &nbsp;
                    <span className="fs--16 fw--400 text-muted">
                        {title.subTitle ? title.subTitle : ""}
                        {/* (Maximum 2) */}
                    </span>
                </h4>
                {data?.length > 0 &&
                    data?.map((item, index) => {
                        const isChecked = selectedName === item.subItem ;
                        return (
                            <Col
                                className="d-flex justify-content-between align-items-center top_cards__sizes"
                                key={index}
                            >
                                <div className="d-flex justify-content-center align-items-center py-2">
                                    <FormGroup check className="mx-2">
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name={item?.subItem}
                                                className="radio"
                                                checked={isChecked}
                                                onChange={() => {
                                                    handleChange(
                                                        item?.subItem,
                                                        item?.price
                                                    );
                                                }}
                                                style={{
                                                    "--accent-color": `${color}`,
                                                }}
                                            />{" "}
                                            <img
                                                alt="cart"
                                                src={
                                                    cart?.images[0]?.url
                                                        ? cart?.images[0]?.url
                                                        : pizzalabel
                                                }
                                                width={25}
                                                className="mx-2"
                                            />
                                        </Label>
                                    </FormGroup>

                                    <h5
                                        className={
                                            darkMode
                                                ? "mb-0 fs--16 fw--600 normal-heading-dark text-capitalize"
                                                : "mb-0 fs--16 fw--600 text-capitalize"
                                        }
                                    >
                                        {item?.subItem}
                                    </h5>
                                </div>
                                <h4
                                    className={
                                        darkMode
                                            ? "mb-0 fs--16 fw--600 normal-heading-dark"
                                            : "mb-0 fs--16 fw--600"
                                    }
                                >
                                    {currency}&nbsp;
                                    {item?.price}
                                </h4>
                            </Col>
                        );
                    })}
            </Row>
        </>
    );
};

export default ItemsDetail;
