import {
    ADD_TO_CART,
    DELETE_CART,
    INCREASE_ITEM_QUANTITY,
    DECREASE_ITEM_QUANTITY,
    CLEAR_CART,
    GET_CHECKOUT_QUESTIONS,
} from "../types";

const initialState = {
    cartItems: [],
    checkoutQuestions: [],
    tipDetails: null,
    tableDetails: null,
    addressDetails: null,
};
let cart = [];
export default function cartReducer(state = initialState, { type, payload }) {
    switch (type) {
        case ADD_TO_CART:
            const itemExists = state.cartItems.some(
                (item) =>
                    item.menuItemID === payload.menuItemID &&
                    item.size === payload.size
            );
            if (itemExists) {
                return state;
            } else {
                return {
                    ...state,
                    cartItems: [...state.cartItems, payload],
                };
            }
        case INCREASE_ITEM_QUANTITY:
            cart = [...state.cartItems];
            const tempCartIndex = cart.findIndex(
                (val) =>
                    val?.menuItemID === payload.menuItemID &&
                    val.size === payload.size
            );
            if (tempCartIndex !== -1) {
                cart[tempCartIndex].quantity += 1;
                cart[tempCartIndex].totalPrice += cart[tempCartIndex].price;
            }
            return {
                ...state,
                cartItems: cart,
            };
        case DECREASE_ITEM_QUANTITY:
            cart = [...state.cartItems];
            const CartIndex = cart.findIndex(
                (val) =>
                    val?.menuItemID === payload.menuItemID &&
                    val.size === payload.size
            );
            if (CartIndex !== -1) {
                if (cart[CartIndex].quantity > 0) {
                    cart[CartIndex].quantity -= 1;
                    cart[CartIndex].totalPrice -= cart[CartIndex].price;
                }
            }
            return {
                ...state,
                cartItems: cart,
            };

        case DELETE_CART:
            cart = [...state.cartItems];
            const cartDeleteIndex = cart?.findIndex(
                (val) =>
                    val?.menuItemID === payload.menuItemID &&
                    val.size === payload.size
            );

            cart.splice(cartDeleteIndex, 1);
            return {
                ...state,
                cartItems: cart,
            };
        case CLEAR_CART:
            return {
                ...state,
                cartItems: [],
                tipDetails: null,
                tableDetails: null,
            };
        case GET_CHECKOUT_QUESTIONS: {
            return {
                ...state,
                checkoutQuestions: payload,
            };
        }
        case "TIP_DETAILS": {
            return {
                ...state,
                tipDetails: payload,
            };
        }
        case "TABLE_UPDATE": {
            return {
                ...state,
                tableDetails: payload,
            };
        }
        case "DELIVERY_UPDATE": {
            return {
                ...state,
                addressDetails: payload,
            };
        }
        default:
            return { ...state };
    }
}
