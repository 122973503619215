import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Spinner,
    UncontrolledTooltip,
} from "reactstrap";
import shareIcon from "../assets/img/shareIcon.svg";
import shareIconL from "../assets/img/shareIconL.svg";
import { checkLiked } from "../store/actions/GlobalAction";

const HeroSectionCard = ({
    slideImages,
    menuID,
    customerID,
    title,
    subTitle,
    content,
}) => {
    const sanitizedHtml = DOMPurify.sanitize(content);
    const [label, setLabel] = useState([]);

    const url =
        "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80";

    const [copied, setCopied] = useState(false);
    async function handleClick() {
        try {
            await navigator.clipboard.writeText(window.location.href);
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 3000);
        } catch (err) {
            console.error("Failed to copy URL: ", err);
        }
    }
    const dispatch = useDispatch();
    const { darkMode, favorite } = useSelector((state) => state.global);

    const [currentSlide, setCurrentSlide] = useState(0);

    const handleSlideChange = (previousSlide, nextSlide) => {
        setCurrentSlide(nextSlide);
    };
    useEffect(() => {
        if (menuID) {
            setLabel(label.slice(0, 5));
            dispatch(checkLiked(menuID, customerID));
        }
    }, [menuID]);

    return (
        <Card
            className={
                darkMode
                    ? "top_cards-dark position-relative"
                    : "top_cards position-relative"
            }
        >
            <div className="slide-container" style={{ position: "relative" }}>
                <Slide
                    autoplay={true}
                    swipe={true}
                    transitionDuration={100}
                    prevArrow={<div className={"hideArrowClass"}></div>}
                    nextArrow={<div className={"hideArrowClass"}></div>}
                    onChange={handleSlideChange}
                >
                    {slideImages && slideImages.length > 0 ? (
                        slideImages.map((slideImage, index) => (
                            <div key={index}>
                                <div className="slides__section">
                                    <img
                                        src={slideImage || url}
                                        alt="SlideImage"
                                    />
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="slides__section">
                            <img src={url} alt="Fallback " />
                        </div>
                    )}
                </Slide>
                {slideImages?.length > 0 && (
                    <div className="numeric-controls">
                        {currentSlide + 1}/{slideImages?.length}
                    </div>
                )}
            </div>
            <CardBody className="">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <CardTitle
                        tag="h4"
                        className={
                            darkMode
                                ? "d-flex align-items-center text-capitalize fs--18 fw--500 mb-0 top_cards-dark__title"
                                : "d-flex align-items-center text-capitalize fs--18 fw--500 mb-0 top_cards__title"
                        }
                    >
                        {title} &nbsp;
                        {label?.map((item, index) => {
                            return (
                                <>
                                    <img
                                        key={item.id}
                                        id={`suggestion_${index}`}
                                        className="cursor-pointer mr-3"
                                        src={item?.labelURL}
                                        width={25}
                                        height={25}
                                        alt=""
                                    />
                                    <UncontrolledTooltip
                                        placement="below"
                                        target={`#suggestion_${index}`}
                                        autohide={false}
                                        style={{
                                            zIndex: 9999,
                                            position: "absolute",
                                            top: "15%",
                                            transform: "translate(-50%, 0)",
                                        }}
                                    >
                                        {item.labelName}
                                    </UncontrolledTooltip>
                                </>
                            );
                        })}
                    </CardTitle>

                    <div>
                        {title && (
                            <>
                                {}
                                <img
                                    alt="Share"
                                    src={darkMode ? shareIcon : shareIconL}
                                    className="mx-2 cursor-pointer"
                                    onClick={handleClick}
                                />
                                {copied && "Copied!"}
                            </>
                        )}
                    </div>
                </div>
                {}

                {content && (
                    <CardText
                        className={
                            darkMode
                                ? "mb-1 normal-heading-dark text-muted fs--14 fw--400"
                                : "mb-1 normal-heading fs--14 fw--400"
                        }
                        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                    />
                )}
            </CardBody>
        </Card>
    );
};

export default HeroSectionCard;
