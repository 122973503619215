import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, Card, Col, Container, Row } from "reactstrap";
import logoD from "../../assets/img/MPink.png";
import logo from "../../assets/img/MPink.png";
import LoginForm from "../../components/LoginForm";
import { signInWithGoogle } from "../../store/actions/authAction";

const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { darkMode, color } = useSelector((state) => state.global);
    const [loginType, setloginType] = useState("");

    return (
        <div className="auth">
            <Container className="py-5">
                <Row className="d-flex flex-column justify-content-center align-items-center">
                    <Col className="d-flex justify-content-center align-items-center flex-column">
                        <h3
                            className={
                                darkMode
                                    ? "fs--30 fw--600 normal-heading-dark mb-4"
                                    : "fs--30 fw--600 normal-heading mb-4"
                            }
                        >
                            Login
                        </h3>
                        <img
                            alt="LOGO"
                            src={darkMode === true ? logo : logoD}
                            className="image_upload__arrow d-flex mx-auto"
                            width="60px"
                        />
                    </Col>
                    <Col className="d-flex mt-5 justify-content-center align-items-center">
                        <Card
                            className={
                                darkMode ? "auth__card-dark" : "auth__card"
                            }
                        >
                            {loginType == "email" ? <LoginForm /> : ""}

                            <Button
                                onClick={() => setloginType("email")}
                                color="primary"
                                style={{ background: `${color}` }}
                                className={`py-3 mb-2 w-100 auth__btn ${
                                    loginType == "email" ||
                                    loginType == "google"
                                        ? "d-none"
                                        : ""
                                }`}
                            >
                                Login with email and password
                            </Button>
                            <Button
                                onClick={() => {
                                    dispatch(signInWithGoogle(history));
                                }}
                                color="primary"
                                style={{ background: `${color}` }}
                                className={`py-3 mt-2 w-100 auth__btn ${
                                    loginType == "email" ||
                                    loginType == "google"
                                        ? "d-none"
                                        : ""
                                }`}
                            >
                                Login with google
                            </Button>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Login;
