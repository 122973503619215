import React, { useEffect, useState } from "react";
import CardItem from "./CardItem";
import { useSelector } from "react-redux";

const Menus = (props) => {
    const { restaurantsDetail } = useSelector((state) => state.restaurants);
    const { menuItems,id } = props;
    const currency = restaurantsDetail?.currency;
    const [menusData, setMenusData] = useState([]);

    useEffect(() => {
        let menuData = [];
        if (menuItems.length > 0) {
            menuItems.forEach((data) => {
                menuData.push({
                    id: data.id,
                    src: data?.images?.[0],
                    title: data?.name,
                    desc: data?.comments,
                    price: data?.sizes?.[0]?.price,
                    currency: currency,
                    label: data?.label,
                    menuDetail: data,
                });
            });
        }

        setMenusData(menuData);
    }, [menuItems]);

    return <CardItem id={id} data={menusData} title={"Menu Items"} />;
};
export default Menus;
