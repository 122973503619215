import React from "react";

const Stepper = ({ steps, completeColor, darkMode }) => {
    function getCompleteColor(predicate) {
        if (predicate) {
            return completeColor;
        }
        return null;
    }

    return (
        <div className="vertical-stepper">
            {steps &&
                steps.map((step, index) => {
                    const nextStep = steps[index + 1];
                    return (
                        <div
                            className={`step ${
                                (!nextStep && step.isComplete) ||
                                (step.isComplete &&
                                    (!nextStep ||
                                        (nextStep && !nextStep.isComplete)))
                                    ? "last-step__completed"
                                    : ""
                            }`}
                            key={`step-${step.key || index}`}
                        >
                            <div className="step-inner">
                                <div
                                    className="step-inner-circle"
                                    style={{
                                        background: getCompleteColor(
                                            step.isComplete
                                        ),
                                    }}
                                />
                                <div
                                    className="step-inner-line"
                                    style={{
                                        background: getCompleteColor(
                                            nextStep &&
                                                nextStep.isComplete &&
                                                step.isComplete
                                        ),
                                    }}
                                />
                            </div>
                            <div className="step-right-content">
                                <h5
                                    className={
                                        darkMode
                                            ? "fs--18 fw--600  normal-heading-dark "
                                            : "fs--18 fw--600  normal-heading"
                                    }
                                >
                                    {step.rightContent}
                                </h5>
                                <p
                                    className={
                                        darkMode
                                            ? "mb-0 fs--12 fw--400 normal-heading-dark"
                                            : "mb-0 fs--12 fw--400"
                                    }
                                >
                                    {step.desc}
                                </p>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};
export default Stepper;
